import React, {useCallback, useState} from "react";
import {Link, useParams, useNavigate} from "react-router-dom";
import {setUserActive, useUser, deleteUser, updateUser} from "../../api/users";
import {Button, Descriptions, Skeleton, Popconfirm, Divider, Input} from "antd";
import AdminLayout from "../_layout/AdminLayout";
import {toOrganization, USERS} from "../../constants/routes.const";
import {EditOutlined} from "@ant-design/icons";
import moment from "moment";

const renderOrganizations = (user) => {
  const activeId = user.ActiveOrganizationId;
  return user.Organizations.map((organization) => {
    return (
      <React.Fragment key={organization.id}>
        <Link to={toOrganization(organization.id)}>
          {organization.name} {organization.id === activeId && " (active)"}
        </Link>
        <br />
      </React.Fragment>
    );
  });
};

const UserPage = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const [, user, loading, reloadUser] = useUser(id);

  const [blockUserLoading, setBlockUserLoading] = useState(false);
  // edit user mobile phone
  const [editMobileMode, setEditMobileMode] = useState(false);
  const [editedPhoneNumber, setEditedPhoneNumber] = useState("");
  const [mobileTouched, setMobileTouched] = useState(false);

  const toggleEditMobileMode = () => {
    setEditedPhoneNumber(user.mobilePhone); // Initialize edited phone number with current phone number
    setEditMobileMode(!editMobileMode);
  };

  // edit user email
  const [editEmailMode, setEditEmailMode] = useState(false);
  const [editedEmail, setEditedEmail] = useState("");
  const [emailTouched, setEmailTouched] = useState(false);

  const toggleEditEmailMode = () => {
    setEditedEmail(user.email); // Initialize edited phone number with current phone number
    setEditEmailMode(!editEmailMode);
  };

  const onUserActiveChange = useCallback(() => {
    setBlockUserLoading(true);
    setUserActive(user.id, !user.isActive)
      .then(() => {
        reloadUser();
      })
      .finally(() => setBlockUserLoading(false));
  }, [user, reloadUser]);

  const handlePhoneNumberChange = (e) => {
    setEditedPhoneNumber(e.target.value);
    setMobileTouched(true);
  };

  const handleEmailChange = (e) => {
    setEditedEmail(e.target.value);
    setEmailTouched(true);
  };

  const savePhoneNumber = async () => {
    await updateUser(user.id, {mobilePhone: editedPhoneNumber});
    setEditMobileMode(false);
    setMobileTouched(false);
    reloadUser();
  };

  const saveEmail = async () => {
    await updateUser(user.id, {email: editedEmail});
    setEditEmailMode(false);
    setEmailTouched(false);
    reloadUser();
  };

  const userWasDaysAgo = (lastLoginAt) => {
    const now = moment(lastLoginAt);
    const today = moment().startOf("day");
    const days = Math.round(moment.duration(today - now).asDays());
    if (!lastLoginAt) return null;
    return days >= 1 ? `${days} days ago` : "today";
  };

  const confirmUserDeletion = async (e) => {
    try {
      await deleteUser(id);
      navigate(USERS);
    } catch (error) {}
  };

  return (
    <AdminLayout>
      <div className="content-box">
        {loading && <Skeleton active />}
        {!loading && user && (
          <>
            <Descriptions
              title={<h1>{`${user.firstName} ${user.lastName}`}</h1>}
              bordered
              column={1}
              labelStyle={{width: 200}}
            >
              <Descriptions.Item label="ID">{user.id}</Descriptions.Item>
              <Descriptions.Item label="Latest login (CET)">
                {userWasDaysAgo(user?.lastLoginAt)}
              </Descriptions.Item>
              <Descriptions.Item label="Email">
                {editEmailMode ? (
                  <>
                    <Input
                      value={editedEmail}
                      onChange={handleEmailChange}
                      style={{width: 200}}
                    />
                    <Button
                      type="primary"
                      onClick={saveEmail}
                      style={{marginLeft: 8}}
                      disabled={!emailTouched || editedEmail === user.email}
                    >
                      Save
                    </Button>
                    <Button onClick={toggleEditEmailMode} style={{marginLeft: 8}}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <>
                    {user.email}{" "}
                    <span onClick={toggleEditEmailMode}>
                      <EditOutlined />
                    </span>
                  </>
                )}
              </Descriptions.Item>

              <Descriptions.Item label="Phone">
                {editMobileMode ? (
                  <>
                    <Input
                      value={editedPhoneNumber}
                      onChange={handlePhoneNumberChange}
                      style={{width: 200}}
                    />
                    <Button
                      type="primary"
                      onClick={savePhoneNumber}
                      style={{marginLeft: 8}}
                      disabled={!mobileTouched || editedPhoneNumber === user.mobilePhone}
                    >
                      Save
                    </Button>
                    <Button onClick={toggleEditMobileMode} style={{marginLeft: 8}}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <>
                    {user.mobilePhone}{" "}
                    <span onClick={toggleEditMobileMode}>
                      <EditOutlined />
                    </span>
                  </>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Personal number">
                {user.personalNumber}
              </Descriptions.Item>
              <Descriptions.Item label="Is active">
                {user.isActive ? "YES" : "NO"}
              </Descriptions.Item>
              <Descriptions.Item label="Created (CET time)">
                {new Date(user.createdAt).toLocaleString("sv")}
              </Descriptions.Item>
              <Descriptions.Item label="Organizations">
                {renderOrganizations(user)}
              </Descriptions.Item>
            </Descriptions>
            <Button
              size="large"
              danger={user.isActive}
              style={{marginTop: 16}}
              loading={blockUserLoading}
              onClick={onUserActiveChange}
            >
              {user.isActive ? "Block user" : "Unblock user"}
            </Button>

            <Popconfirm
              title={"Are you sure?"}
              onConfirm={confirmUserDeletion}
              okText="Yes"
              cancelText="No"
            >
              <Button size="large" type="primary" danger style={{margin: 16}}>
                Delete user
              </Button>
            </Popconfirm>
            <Divider />
            <h1> Assignee in Project Items:</h1>
            {user?.projectItems?.length ? (
              <div>
                {user.projectItems.map((assignee) => (
                  <div key={assignee.id}>
                    <p>
                      id: {assignee.id} -- name: {assignee.name}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <div>Empty</div>
            )}
            <Divider />
            <h1>Assigned as a contact:</h1>
            {user?.contacts?.length ? (
              <div>User assigned as an organization contact.</div>
            ) : (
              <div>Empty</div>
            )}
            <Divider />
            <h1>Messages:</h1>
            {user?.messages?.length ? (
              <div>The user has written {user.messages.length} messages</div>
            ) : (
              <div>Empty</div>
            )}
            <Divider />
            <h1>Contact person in following RFT's:</h1>
            {user?.requests?.length ? (
              <div>
                {user.requests.map((contact) => (
                  <div key={contact.id}>
                    <p>id: {contact.id} </p>
                  </div>
                ))}
              </div>
            ) : (
              <div>Empty</div>
            )}
            <Divider />
            <h1>Contact in offer adjustments:</h1>
            {user?.offerAdjustmentsContacts?.length ? (
              <div>
                {user.offerAdjustmentsContacts.map((contact) => (
                  <div key={contact.id}>
                    <p>
                      id: {contact.id} -- Offer ID: {contact.OfferId} -- Created At:
                      {contact.createdAt}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <div>Empty</div>
            )}
            <Divider />
            <h1>Subscription orders:</h1>
            {user?.subscribeUnits?.length ? (
              <div>
                {user.subscribeUnits.map((c) => (
                  <div key={c.id}>
                    <p>id: {c.id}</p>
                  </div>
                ))}
              </div>
            ) : (
              <div>Empty</div>
            )}
            <Divider />
            <h1>User in contracts (СontractsBuyerSignatories):</h1>
            {user?.contractsBuyerSignatories?.length ? (
              <div>
                {user.contractsBuyerSignatories.map((c) => (
                  <div key={c.id}>
                    <p>id: {c.id}</p>
                  </div>
                ))}
              </div>
            ) : (
              <div>Empty</div>
            )}
            <Divider />
            <h1>User in organization join requests:</h1>
            {user?.orgJoinRequests?.length ? (
              <div>
                {user.orgJoinRequests.map((c) => (
                  <div key={c.OrganizationId}>
                    <p>OrganizationId: {c.OrganizationId}</p>
                  </div>
                ))}
              </div>
            ) : (
              <div>Empty</div>
            )}
            <Divider />
            <h1>Received requests for tenders(Proposals):</h1>
            {user?.proposals?.length ? (
              <div>
                {user.proposals.map((c) => (
                  <div key={c.id}>
                    <p>id: {c.id}</p>
                  </div>
                ))}
              </div>
            ) : (
              <div>Empty</div>
            )}
            <Divider />
            <h1>
              Receive notifications for requests for tenders(ProposalMembersToReceive):
            </h1>
            {user?.proposalMembersToReceive?.length ? (
              <div>
                {user.proposalMembersToReceive.map((c) => (
                  <div key={c.ProposalId}>
                    <p>Tender(ProposalId): {c.ProposalId}</p>
                  </div>
                ))}
              </div>
            ) : (
              <div>Empty</div>
            )}
            <Divider />
            <h1>Assignee in organization agreements(OrganizationAgreementItems):</h1>
            {user?.organizationAgreementItems?.length ? (
              <div>
                {user.organizationAgreementItems.map((c) => (
                  <div key={c.OrganizationAgreementId}>
                    <p>OrganizationAgreementId: {c.OrganizationAgreementId}</p>
                  </div>
                ))}
              </div>
            ) : (
              <div>Empty</div>
            )}
            <Divider />
            <h1>Reject message contact in offers:</h1>
            {user?.offers?.length ? (
              <div>
                {user.offers.map((c) => (
                  <div key={c.id}>
                    <p>OfferId: {c.id}</p>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                The user is not mentioned as a contact for a reject message in offers
              </div>
            )}
            <Divider />
            <h1>Projects Member:</h1>
            {user?.projectMembers?.length ? (
              <div>
                {user.projectMembers.map((c, i) => (
                  <div key={c.ProjectId}>
                    <p>ProjectId: {c.ProjectId}</p>
                  </div>
                ))}
              </div>
            ) : (
              <div>Empty</div>
            )}
            <Divider />

            <h1>RequestHardDateCloseOffersHistories:</h1>
            {user?.requestHardDateCloseOffersHistories?.length ? (
              <div>
                {user.requestHardDateCloseOffersHistories.map((r, i) => (
                  <div key={r.id}>
                    <p>id: {r.id}</p>
                  </div>
                ))}
              </div>
            ) : (
              <div>Empty</div>
            )}
            <Divider />
          </>
        )}
      </div>
    </AdminLayout>
  );
};

export default UserPage;
