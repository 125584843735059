import React from "react";
import TranslationListHeader from "./TranslationListHeader";
import TranslationElement from "../TranslationElement/TranslationElement";
import TranslationsPagination from "./TranslationsPagination";
import FormTranslationElement from "../FormTranslationElement/FormTranslationElement";
import {inject, observer} from "mobx-react";
import {Preloader} from "components/Preloader";

const TranslationsListContent = inject("store")(
  observer(({store: {admin}}) => {
    const {
      translationData: {totalItems},
    } = admin;

    const translationsList = !admin.loadingTranslations ? (
      <>
        {admin.translationData.translations?.map((item) => (
          <TranslationElement key={item.id} transValues={item} />
        ))}
        {totalItems > 20 && <TranslationsPagination />}
      </>
    ) : (
      <Preloader />
    );

    return (
      <div className="admin-translation_list">
        <TranslationListHeader />
        {admin.isAddNewTransFormOpen && <FormTranslationElement isNewElement />}
        {translationsList}
      </div>
    );
  })
);

export default TranslationsListContent;
