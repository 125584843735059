import React, {useCallback, useState} from "react";
import {Button, Form, Input} from "antd";
import {inviteUsers} from "../../../api/organizations";
import {showSuccess} from "../../../helpers/notifications.helper";

export const InviteForm = ({organizationId}) => {
  const fileRef = React.useRef();
  const [inviteForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const onInvite = useCallback(async () => {
    const values = inviteForm.getFieldsValue();

    const files = fileRef.current.input.files;

    setLoading(true);
    await inviteUsers(organizationId, {...values, file: files[0]})
      .then(() => {
        inviteForm.resetFields();
        showSuccess("Invites is sent");
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Form form={inviteForm} layout="vertical" onFinish={onInvite} on>
      <Form.Item
        label="attach CSV file (email and companyName required)"
        name="file"
        required
      >
        <Input type="file" accept=".csv" ref={fileRef} />
      </Form.Item>
      <Form.Item label="Invite by (Firstname)" name="firstName" required>
        <Input />
      </Form.Item>
      <Form.Item label="Invite by (Lastname)" name="lastName" required>
        <Input />
      </Form.Item>
      <Form.Item label="Invite by (Phone)" name="inviteByPhone" required>
        <Input />
      </Form.Item>
      <Form.Item label="Invite by (Email)" name="inviteByEmail" required>
        <Input />
      </Form.Item>
      <Form.Item
        label="Direct Company Invite (Personal Message)"
        name="directInviteMessage"
        required
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Invite
        </Button>
      </Form.Item>
    </Form>
  );
};
