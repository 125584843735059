import React, {useState, useEffect, useCallback, useMemo} from "react";
import {Button, Input, Upload, DatePicker, Switch} from "antd";
import moment from "moment";

import {beforeUploadPNGJPEGSVG} from "helpers/uploader";
import noLogo from "assets/images/No-logo.svg";
import {acceptImages} from "constants/accept.consts";

export const AdvertisementItem = ({
  ad,
  onCreate,
  onUpdate,
  onRemove,
  onReset,
  btnLoading,
}) => {
  const [currentName, setCurrentName] = useState("");
  const [currentLink, setCurrentLink] = useState("");
  const [currentVideoLink, setCurrentVideoLink] = useState("");
  const [currentDate, setCurrentDate] = useState([moment(), moment()]);
  const [currentLogo, setCurrentLogo] = useState(noLogo);
  const [isImageType, setImageType] = useState(ad?.type !== "video");
  const {RangePicker} = DatePicker;

  const disabledDate = (current) => Date.parse(current?.format()) < Date.now();
  const disabledCreateUpdateButton = !(
    currentName &&
    currentLink &&
    currentDate[0] &&
    currentDate[1] &&
    (isImageType ? true : currentVideoLink)
  );

  const isEdit = !!ad?.id;

  const formData = useMemo(
    () => ({
      name: currentName,
      link: currentLink,
      linkDesktop: isImageType ? null : currentVideoLink,
      startAt: currentDate[0],
      endAt: currentDate[1],
      type: isImageType ? "image" : "video",
    }),
    [currentName, currentLink, currentVideoLink, currentDate, isImageType]
  );

  const onChangeAD = useCallback(
    (isChanged) => {
      setCurrentName(isChanged ? ad?.name ?? "" : "");
      setCurrentLink(isChanged ? ad?.link ?? "" : "");
      setCurrentVideoLink(isChanged ? ad?.linkDesktop ?? "" : "");
      setCurrentDate(
        isChanged
          ? [
              ad?.startAt ? moment(ad?.startAt) : moment(),
              ad?.endAt ? moment(ad?.endAt) : moment(),
            ]
          : [moment(), moment()]
      );
      setImageType(ad?.type !== "video");
      setCurrentLogo(
        isChanged ? ad?.AdvertisementImageFile?.File?.url ?? noLogo : noLogo
      );
      !isChanged && onReset();
    },
    [ad, onReset]
  );

  useEffect(() => {
    onChangeAD(true);
  }, [ad, onChangeAD]);

  return (
    <div>
      <div className="df-row-center">
        {currentLogo && isImageType && (
          <img
            src={currentLogo}
            alt=""
            height="80"
            style={{maxWidth: "250px", minWidth: isEdit ? undefined : "184.5px"}}
            onError={() => setCurrentLogo(noLogo)}
          />
        )}

        <div className="df-column ml-4" style={{width: "50%"}}>
          <div className="df-row-center mb-1">
            <Input
              placeholder="AD name"
              value={currentName}
              onChange={(e) => setCurrentName(e.target.value)}
              className="mr-4"
            />

            <RangePicker
              format="DD/MM/YYYY"
              placeholder={["Start AD date", "End AD date"]}
              value={currentDate}
              disabledDate={disabledDate}
              onChange={setCurrentDate}
              style={{width: "100%"}}
            />
          </div>

          <div className="df-row-center mb-1">
            <Switch
              checked={isImageType}
              onChange={setImageType}
              checkedChildren="Image"
              unCheckedChildren="VideoLink"
              style={{width: "115px", marginRight: "10px"}}
            />
            <Input
              placeholder="Link to video"
              value={currentVideoLink}
              disabled={isImageType}
              onChange={(e) => setCurrentVideoLink(e.target.value)}
            />
          </div>

          <Input
            placeholder="AD link"
            value={currentLink}
            onChange={(e) => setCurrentLink(e.target.value)}
          />
        </div>
        <div className="df-column ml-4">
          {isEdit || !isImageType ? (
            <Button
              className="mb-1"
              type="primary"
              loading={btnLoading}
              onClick={() =>
                isImageType || isEdit
                  ? onUpdate({
                      ...ad,
                      ...formData,
                    })
                  : onCreate(formData)
              }
              disabled={disabledCreateUpdateButton}
            >
              {isImageType ? "Update" : "Create"}
            </Button>
          ) : (
            <Upload
              accept={acceptImages}
              beforeUpload={beforeUploadPNGJPEGSVG}
              customRequest={({file}) => onCreate(formData, file)}
              showUploadList={false}
            >
              <Button
                type="primary"
                loading={btnLoading}
                disabled={disabledCreateUpdateButton}
              >
                Create
              </Button>
            </Upload>
          )}
          {!!ad?.id && (
            <Button
              danger
              type="primary"
              onClick={() => onRemove(ad?.id)}
              loading={btnLoading}
            >
              Remove
            </Button>
          )}
          <Button type="ghost" className="mt-1" onClick={() => onChangeAD()}>
            Reset
          </Button>
        </div>
      </div>
    </div>
  );
};
