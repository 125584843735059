import React from "react";
import AdminLayout from "../_layout/AdminLayout";
import {useApplyingUsers} from "../../api/applyingusers";
import {UsersTable} from "./UsersTable";

const ApplyingUsers = () => {
  const [, usersData, loading] = useApplyingUsers();

  return (
    <AdminLayout>
      <div className="content-box">
        <h1>Applying Users pending approval ({usersData?.length ?? "0"} user(s))</h1>
        <UsersTable loading={loading} users={usersData} pagination={false} />
      </div>
    </AdminLayout>
  );
};

export default ApplyingUsers;
