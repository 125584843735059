import React, {memo} from "react";

export const IconEdit = memo(() => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.104 0.896024C18.542 0.334024 17.795 0.0250244 17 0.0250244C16.205 0.0250244 15.458 0.334024 14.896 0.896024L2.14595 13.646C2.09395 13.698 2.05495 13.76 2.02995 13.829L0.0299518 19.329C-0.0360482 19.512 0.00895178 19.716 0.145952 19.853C0.240952 19.948 0.368952 19.999 0.499952 19.999C0.556952 19.999 0.614952 19.989 0.670952 19.969L6.17095 17.969C6.23995 17.944 6.30195 17.904 6.35395 17.853L19.104 5.10302C19.666 4.54102 19.975 3.79402 19.975 2.99902C19.975 2.20402 19.666 1.45702 19.104 0.895024V0.896024ZM5.72495 17.068L1.33595 18.664L2.93195 14.275L14 3.20702L16.793 6.00002L5.72495 17.068ZM18.396 4.39602L17.5 5.29202L14.707 2.49902L15.603 1.60302C15.976 1.23002 16.472 1.02502 16.999 1.02502C17.526 1.02502 18.022 1.23002 18.395 1.60302C18.768 1.97602 18.973 2.47202 18.973 2.99902C18.973 3.52602 18.768 4.02202 18.395 4.39502L18.396 4.39602Z"
        fill="#1BA2CC"
      />
    </svg>
  );
});

export default IconEdit;
