import i18next from "i18next";
import {showSuccess} from "./notifications.helper";
import {subscriptionPriceKeys} from "constants/user.consts";

export const checkField = (msg) => {
  const str = msg.replace(/([A-Z])/g, " $1").toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function updateLocationHash(hash) {
  window.location.hash = hash;
}

export const abbreviationWords = (str, isNeedToShowDots) => {
  const dots = isNeedToShowDots ? "..." : "";
  return str.substr(0, 4) + dots;
};

export const digitsBy2 = (x) => {
  return Number.parseFloat(x).toFixed(2);
};

export const createArray = (lng) => Array.from(new Array(lng));

export const constTemplate = ({name, number}) => {
  name = name.toString().toUpperCase();
  number += 1;
  number = number.toString();
  number = number.length < 2 ? `0${number}` : number;
  return {
    label: `${name}_${number}`,
    value: `${name}_${number}`,
  };
};

export const openNewTab = (url) => {
  url = url.match(/^http[s]?:\/\//) ? url : `http://${url}`;
  window.open(url, "_blank");
};

export const trimString = (str, length = 20) => {
  if (str) {
    if (str.length <= length) {
      return str;
    }
    return `${str.substring(0, length)}...`;
  }
};

export const combineArrayOfObjects = (memo, curr) => Object.assign(memo, curr);

export const getDomain = (url, subdomain) => {
  if (!url) {
    return null;
  }

  subdomain = subdomain || false;

  url = url.replace(/(https?:\/\/)?(www.)?/i, "");

  if (!subdomain) {
    url = url.split(".");

    url = url.slice(url.length - 2).join(".");
  }

  if (url.indexOf("/") !== -1) {
    return url.split("/")[0];
  }

  return url;
};

export const sumKeyValueObjectsInArray = (items, key) => {
  if (Array.isArray(items)) {
    return items.reduce((a, b) => a + (b[key] || 0), 0);
  }
};

export const getFullNameInitialLetters = (fullName) => {
  const initials = fullName?.match(/\b\w/g) || [];
  return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
};

export const formatCompanyNumber = (value, restore) => {
  /***
   * return company format as xxxx-xxxxxx
   * else if restore === true, return restored value (xxxx-xxxxxx -> xxxxxxxxxx)
   * ***/
  if (!value) {
    return "";
  }

  if (restore === true) {
    return value.toString().trim().replace(/[-/x]/g, "");
  }

  if (value.length > 5) {
    return `${value.substring(0, 4)}-${value.substring(4)}`;
  }
  return value;
};

export const requestImitation = (delay) => {
  /*** Request imitation promise with default delay 500 ***/
  return new Promise(function (resolve) {
    setTimeout(resolve, delay || 500);
    showSuccess("Request Imitation Successfully submitted!");
  });
};

export const getFiles = (item) => {
  if (item?.Files?.length) {
    return item.Files.map((file) => ({
      url: file.url,
      name: file.name,
    }));
  }
  return null;
};

export const getFirstLetter = (word) => {
  return word
    ?.trim()
    .split(" ")
    .map((n) => n[0])
    .join("");
};

export const getMessage = (message) => {
  const language = i18next.language;
  if (typeof message === "string") {
    return message;
  } else if (typeof message === "object" && Object.keys(message).length) {
    return message[language];
  }
};

export const arrayToCommaAnd = (items, lastAnd = " and ", comma = ", ") =>
  items?.reduce(
    (str, item, i) =>
      (str += `${item}${
        i < items.length - 2 ? comma : i < items.length - 1 ? lastAnd : ""
      }`),
    [""]
  );

export const isEqualArrays = (currentArr, initialArr) =>
  JSON.stringify(currentArr.sort()) === JSON.stringify(initialArr.sort());

export const subscriptionPrice = (subscription, period) =>
  subscription[subscriptionPriceKeys[period]];
