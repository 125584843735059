import React, {useState, useCallback} from "react";
import {Pagination, Form, Input, Button, Select} from "antd";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
// local
import AdminLayout from "../_layout/AdminLayout";
import {useOrganizations} from "../../api/organizations";
import {OrganizationsTable} from "./components/OrganizationsTable";
import {subscriptionPrice} from "helpers/helper";

const OrganizationsPage = inject("store")(
  observer(({store: {admin}}) => {
    const {subscriptions} = admin;
    const [searchForm] = Form.useForm();
    const {Option} = Select;
    const {t} = useTranslation();
    const [{name, description, customerId}, setFilter] = useState({});
    const pageSize = 20;
    const [page, setPage] = useState(1);
    const [SubscribeProductId, setSubscribeProductId] = useState(null);
    const [, organizationsData, loading] = useOrganizations(
      name,
      description,
      customerId,
      SubscribeProductId,
      page,
      pageSize
    );

    const onReset = useCallback(() => {
      searchForm.resetFields();
      setSubscribeProductId(null);
      setFilter({name: "", description: "", customerId: null});
    }, [searchForm]);

    const onSearch = useCallback(() => {
      setFilter({
        ...searchForm.getFieldsValue(),
        SubscribeProductId,
      });
    }, [setFilter, searchForm, SubscribeProductId]);

    return (
      <AdminLayout>
        <div className="content-box organizations">
          <h1>Organizations ({organizationsData?.totalItems ?? "0"} items)</h1>
          <div className="df-row-center">
            <h3 className="subtitle mr-6">Search -&gt;</h3>
            <Form form={searchForm} layout="inline" onFinish={onSearch}>
              <Form.Item label="Name" name="name">
                <Input />
              </Form.Item>
              <Form.Item label="Description" name="description">
                <Input />
              </Form.Item>
              <Form.Item label="Customer ID" name="customerId">
                <Input />
              </Form.Item>
              <div>
                Subscription:
                <Select
                  className="ml-2 mr-4"
                  style={{width: "130px"}}
                  value={SubscribeProductId}
                  onChange={setSubscribeProductId}
                  key={"name"}
                >
                  {!!subscriptions?.length &&
                    subscriptions
                      .filter((item) => item.allow)
                      .map((item, i) => (
                        <Option value={item.id} key={i}>
                          {`${t(item.name)} (${subscriptionPrice(
                            item,
                            item?.pricePeriodDefault
                          )?.replace(".00", "")} kr)`}
                        </Option>
                      ))}
                </Select>
              </div>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Search
                </Button>
              </Form.Item>
              <Form.Item>
                <Button htmlType="button" onClick={onReset}>
                  Reset
                </Button>
              </Form.Item>
            </Form>
          </div>

          <OrganizationsTable
            loading={loading}
            dataSource={organizationsData?.organizations}
            pagination={false}
            style={{marginTop: 15}}
          />
          <Pagination
            total={organizationsData?.totalItems}
            current={page}
            pageSize={pageSize}
            showSizeChanger={false}
            onChange={setPage}
            style={{marginTop: 15}}
          />
        </div>
      </AdminLayout>
    );
  })
);

export default OrganizationsPage;
