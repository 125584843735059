import React from "react";
import {ErrorMessage, Field} from "formik";
//local
import {checkField} from "helpers/helper";

const SimpleField = ({errorName, label, ...props}) => {
  return (
    <div className="form">
      {label ? <label className="label">{label}</label> : null}
      <Field {...props} />
      {errorName && (
        <ErrorMessage name={errorName}>
          {(msg) => <p style={{color: "red"}}>{checkField(msg)}</p>}
        </ErrorMessage>
      )}
    </div>
  );
};

export default SimpleField;
