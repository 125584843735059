import React, {useState, useCallback} from "react";
import {Select, Pagination, Form, Input, Button, DatePicker} from "antd";
import moment from "moment";
import {useTranslation} from "react-i18next";
// local
import AdminLayout from "../_layout/AdminLayout";
import {useSubscriptionOrders} from "../../api/organizations";
import {SubscriptionsOrdersTable} from "./components/SubscriptionsOrdersTable";

const SubscriptionsOrdersPage = () => {
  const {t} = useTranslation();
  const [searchForm] = Form.useForm();
  const {RangePicker} = DatePicker;
  const [{name, fromDate, toDate}, setFilter] = useState({});
  const pageSize = 20;
  const [page, setPage] = useState(1);
  const [subscribeProductNames, setSubscribeProductNames] = useState([]);

  const [, subscriptionOrders, loading] = useSubscriptionOrders({
    subscribeProductNames,
    name,
    fromDate,
    toDate,
    page,
    pageSize,
  });
  const disabledDate = (current) => Date.parse(current?.format()) > Date.now();

  const onReset = useCallback(() => {
    searchForm.resetFields();
    setFilter({
      subscribeProductNames: [],
      name: "",
      fromDate: "2020-01-01",
      toDate: moment().format("YYYY-MM-DD"),
    });
    setSubscribeProductNames([]);
  }, [searchForm]);

  const onSearch = useCallback(() => {
    const values = searchForm.getFieldsValue();
    setFilter({
      subscriptionNames: values.subscriptionNames,
      name: values.name,
      fromDate: values.dateRange?.[0].format("YYYY-MM-DD"),
      toDate: values.dateRange?.[1].format("YYYY-MM-DD"),
    });
  }, [setFilter, searchForm]);

  const createSubscriptionNames = () => {
    const subscriptionNames = [];
    for (let i = 0; i < 6; i++) {
      const subscriptionName = `SUBSCRIPTION_NAME_0${i + 1}`;
      subscriptionNames.push({value: subscriptionName, label: t(subscriptionName)});
    }
    return subscriptionNames;
  };

  return (
    <AdminLayout>
      <div className="content-box organizations">
        <h1>Subscription orders ({subscriptionOrders?.totalItems ?? "0"} items)</h1>
        <div className="df-row-center">
          <h3 className="subtitle mr-6">Search -&gt;</h3>
          <Form form={searchForm} layout="inline" onFinish={onSearch}>
            <Form.Item label="Org. name" name="name">
              <Input />
            </Form.Item>
            <Form.Item label="Subscription:" name="subscriptionNames">
              <Select
                className="ml-2 mr-4"
                style={{width: "200px"}}
                mode="multiple"
                value={subscribeProductNames}
                onChange={(values) => {
                  setSubscribeProductNames(values);
                }}
                size={"medium"}
                placeholder="Select subscription"
                options={createSubscriptionNames()}
              />
            </Form.Item>
            <Form.Item label="Orders range" name="dateRange">
              <RangePicker
                format="DD/MM/YYYY"
                defaultValue={[moment("2020-01-01", "YYYY-MM-DD"), moment()]}
                disabledDate={disabledDate}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Search
              </Button>
            </Form.Item>
            <Form.Item>
              <Button htmlType="button" onClick={onReset}>
                Reset
              </Button>
            </Form.Item>
          </Form>
        </div>

        <SubscriptionsOrdersTable
          loading={loading}
          dataSource={subscriptionOrders?.data}
          pagination={false}
          style={{marginTop: 15}}
        />

        <Pagination
          total={subscriptionOrders?.totalItems}
          current={page}
          pageSize={pageSize}
          showSizeChanger={false}
          onChange={setPage}
          style={{marginTop: 15}}
        />
      </div>
    </AdminLayout>
  );
};

export default SubscriptionsOrdersPage;
