import React from "react";
import AdminLayout from "../_layout/AdminLayout";
import {useOrgsNoBankId} from "../../api/orgsNoBankId";
import {OrganizationsTable} from "./OrganizationsTable";

const OrgsNoBankid = () => {
  const [, orgsData, loading] = useOrgsNoBankId();

  return (
    <AdminLayout>
      <div className="content-box">
        <h1>Organizations without BankId ({orgsData?.length ?? "0"} organizations)</h1>
        <OrganizationsTable
          loading={loading}
          dataSource={orgsData}
          pagination={false}
          style={{marginTop: 15}}
        />
      </div>
    </AdminLayout>
  );
};

export default OrgsNoBankid;
