import React, {memo} from "react";
import cn from "classnames";
import {IconEdit} from "../icons";

const ButtonEdit = memo(({onClick, addClass}) => {
  return (
    <button
      className={cn("btn-custom__edit", {[addClass]: !!addClass})}
      onClick={onClick}
      type="button"
    >
      <IconEdit />
    </button>
  );
});

export default ButtonEdit;
