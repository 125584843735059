import React, {useState} from "react";
import {inject, observer} from "mobx-react";
import {Form, Formik} from "formik";
import {useTranslation} from "react-i18next";
import {schema, initialValues} from "./formAttrs";
import FormikField from "components/FormikField";
import {Link, useNavigate} from "react-router-dom";
import {ADMIN_LOGIN, ADMIN_RESET_PASS_DONE} from "../../../constants/routes.const";
import AdminFormsLayout from "../../../components/AdminFormsLayout/AdminFormsLayout";
import {Button} from "components/buttons/Button";
import {showSuccess} from "helpers/notifications.helper";

const AdminResetPassPage = inject("store")(
  observer(({store: {admin}}) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const onSubmit = (values) => {
      setLoading(true);
      admin
        .recoverAdminPass(values)
        .then(() => {
          showSuccess("Successfully submitted!");
          navigate(ADMIN_RESET_PASS_DONE);
        })
        .finally(() => setLoading(false));
    };

    return (
      <AdminFormsLayout
        addClass="admin_reset__wrapper"
        formTitle={t("LOGIN_RESET_PASSWORD")}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({dirty, isValid}) => (
            <Form>
              <FormikField
                label={t("E_MAIL")}
                name="email"
                errorName="email"
                placeholder={t("LOGIN_PLACEHOLDER_EMAIL")}
                className="admin_authentication__form __field"
              />
              <div className="admin_authentication__form __actions">
                <Button
                  type="submit"
                  loading={loading}
                  name={t("LOGIN_RESET_PASSWORD")}
                  disabled={!dirty || !isValid}
                />
              </div>
            </Form>
          )}
        </Formik>
        <div className="admin_authentication__link __wrapper">
          <span>
            {t("LOGIN_ALREADY_HAVE_LOGIN")} <Link to={ADMIN_LOGIN}>{t("LOGIN")}</Link>
          </span>
        </div>
      </AdminFormsLayout>
    );
  })
);

export default AdminResetPassPage;
