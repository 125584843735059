import React from "react";
import {ErrorMessage} from "formik";
import {checkField} from "helpers/helper";
import {Input} from "formik-antd";

const FormikField = ({errorName, label, ...props}) => {
  return (
    <div className="form-field_wrapper">
      {label && <label>{label}</label>}
      <Input {...props} />
      {errorName && (
        <ErrorMessage name={errorName}>
          {(msg) => <p style={{color: "red"}}>{checkField(msg)}</p>}
        </ErrorMessage>
      )}
    </div>
  );
};

export default FormikField;
