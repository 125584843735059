import React, {useState} from "react";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import downloadPdf from "assets/images/svg/download-pdf.svg";
import {Upload} from "antd";
import {showError, showSuccess, showWarning} from "helpers/notifications.helper";
import {inject, observer} from "mobx-react";

const UploadTranslationsBase = inject("store")(
  observer(({store: {admin}}) => {
    const [loading, setLoading] = useState();

    const props = {
      name: "file",
      onChange(info) {
        if (info.file.status === "uploading") {
          setLoading(true);
        }
        if (info.file.status === "done") {
          showSuccess(`${info.file.name} file uploaded successfully`);
          setLoading(false);
        } else if (info.file.status === "error") {
          showError(`${info.file.name} file upload failed.`);
          setLoading(false);
        }
      },
    };

    const beforeUpload = (file) => {
      const isCsv = file.type === "text/csv";
      if (!isCsv) {
        showWarning("You can only upload CSV file!");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        showWarning("File must smaller than 5MB!");
        return false;
      }
      return isCsv;
    };

    const customRequest = ({file}) => {
      admin.uploadTranslations(file).finally(() => setLoading(false));
    };

    return (
      <Upload
        {...props}
        name="file"
        listType="translation-data"
        showUploadList={false}
        beforeUpload={beforeUpload}
        customRequest={customRequest}
        className="admin_trans__uploader"
      >
        <ButtonTransparent
          name="Upload"
          addClass="__upload"
          type="button"
          img={downloadPdf}
          loading={loading}
        />
      </Upload>
    );
  })
);

export default UploadTranslationsBase;
