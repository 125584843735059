import React, {useState} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {schema, initialValues} from "./formAttrs";
import {ReactNumberInput} from "components/ReactNumberInput";
import {Formik, Form} from "formik";
import {Link} from "react-router-dom";
import {ADMIN_LOGIN} from "../../../../../constants/routes.const";
import {Button} from "components/buttons/Button";
import {showSuccess} from "helpers/notifications.helper";

const AdminRegisterPhonePage = inject("store")(
  observer(({store: {admin}}) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const current = admin.registerStep;
    const [isValidPhone, setIsValidPhone] = useState(false);

    const handleSubmit = (values) => {
      setLoading(true);
      admin
        .sendAdminVerificationCode(values)
        .then(() => {
          showSuccess("Successfully submitted!");
          admin.setRegisterStep(current + 1);
        })
        .finally(() => setLoading(false));
    };

    return (
      <>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({values, isValid, setFieldValue}) => {
            return (
              <Form>
                <label className="label">{t("LOGIN_PHONE")}</label>
                <ReactNumberInput
                  phone={values.mobilePhone}
                  setPhone={(value) => {
                    values.mobilePhone = value;
                    setFieldValue("mobilePhone", value);
                  }}
                  setIsValid={setIsValidPhone}
                />
                <div className="admin_authentication__form __actions">
                  <Button
                    type="submit"
                    loading={loading}
                    disabled={!isValid || !isValidPhone}
                    name={t("LOGIN_SEND_MESSAGE")}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
        <div className="admin_authentication__link __wrapper">
          <span>
            {t("LOGIN_ALREADY_HAVE_LOGIN")} <Link to={ADMIN_LOGIN}>{t("LOGIN")}</Link>
          </span>
        </div>
      </>
    );
  })
);

export default AdminRegisterPhonePage;
