import React, {useState} from "react";
import {Button} from "antd";

export const CreditReportCompanies = ({activeStatus, submitStatus, loading}) => {
  const [status, setStatus] = useState(activeStatus);
  return (
    <div>
      <div className="df-row-center">
        <span className="__bold mr-4">{status ? "ENABLED" : "DISABLED"}</span>
        <Button
          type="primary"
          onClick={() => {
            setStatus(!status);
            submitStatus(!status);
          }}
          loading={loading}
        >
          Change
        </Button>
      </div>
    </div>
  );
};
