import React from "react";
import AdminLayout from "../_layout/AdminLayout";

const AdminDashboard = () => {
  return (
    <AdminLayout>
      <div className="content-box">
        <h1>HandyDay AB</h1>
      </div>
    </AdminLayout>
  );
};

export default AdminDashboard;
