import React, {useState} from "react";
import cn from "classnames";
import {Form, Formik} from "formik";
import {initialValues, schema} from "./formAttrs";
import FormikField from "components/FormikField";
import SimpleField from "components/SimpleField";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {inject, observer} from "mobx-react";
import {showSuccess} from "helpers/notifications.helper";
import {Modal} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {catchFieldErrors} from "../../../../helpers/formik.helpers";

const FormTranslationElement = inject("store")(
  observer(({store: {admin}, values, isNewElement, isEditElement, closeEdit}) => {
    const [loadingSubmit, setLoadingSubmit] = useState();
    const [loadingDelete, setLoadingDelete] = useState();
    const {confirm} = Modal;

    const showConfirm = (transId) => {
      setLoadingDelete(true);
      confirm({
        title: "Do you want to delete this translation?",
        icon: <ExclamationCircleOutlined />,
        content: "If you delete it, you will not be able to return it back.",
        onOk: () => deleteEvent(transId),
        onCancel: () => setLoadingDelete(false),
      });
    };

    const deleteEvent = (transId) => {
      admin
        .deleteTranslationElement(transId)
        .then(() => showSuccess("You deleted translation"))
        .finally(() => setLoadingDelete(false));
    };

    const closeTransElementForm = () => {
      if (isNewElement) {
        admin.setOpenNewTranslationForm(false);
      }
      if (isEditElement) {
        closeEdit();
      }
    };

    const createNewTranslation = (values, setFieldError) => {
      admin
        .addNewTranslationElement(values)
        .then(() => {
          admin.setOpenNewTranslationForm(false);
          showSuccess("Translation successfully added!");
        })
        .catch((e) => catchFieldErrors(e, setFieldError))
        .finally(() => setLoadingSubmit(false));
    };

    const updateTranslation = (values, setFieldError) => {
      admin
        .editTranslationElement(values)
        .then(() => {
          showSuccess("Translation is successfully edited!");
          closeEdit();
        })
        .catch((e) => catchFieldErrors(e, setFieldError))
        .finally(() => setLoadingSubmit(false));
    };

    const onSubmit = (values, {setFieldError}) => {
      setLoadingSubmit(true);
      isEditElement
        ? updateTranslation(values, setFieldError)
        : createNewTranslation(values, setFieldError);
    };

    return (
      <div
        className={cn(
          "admin-translation_item__wrapper",
          {__new: isNewElement},
          {__edit: isEditElement}
        )}
      >
        <Formik
          initialValues={values || initialValues}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          <Form>
            <div className="admin-translation_item__inner-wrapper">
              <div className="admin-translation_item__text">
                <FormikField
                  name="key"
                  errorName="key"
                  placeholder="Enter Key"
                  disabled={loadingSubmit}
                />
              </div>
              <div className="admin-translation_item__text">
                <SimpleField
                  name="en"
                  placeholder="Enter translation"
                  errorName="en"
                  as="textarea"
                  className="trans__field"
                  disabled={loadingSubmit}
                />
              </div>
              <div className="admin-translation_item__text">
                <SimpleField
                  name="sv"
                  placeholder="Enter translation"
                  errorName="sv"
                  as="textarea"
                  className="trans__field"
                  disabled={loadingSubmit}
                />
              </div>
              <div className="admin-translation_item__text">
                <SimpleField
                  name="fi"
                  placeholder="Enter translation"
                  errorName="fi"
                  as="textarea"
                  className="trans__field"
                  disabled={loadingSubmit}
                />
              </div>
              <div className="admin-translation_item__action">
                <ButtonTransparent
                  type="submit"
                  name={isEditElement ? "Save" : "Add translation"}
                  addClass="admin-translation_item__btn __save"
                  loading={loadingSubmit}
                />
                {isEditElement && (
                  <ButtonTransparent
                    type="button"
                    name="Delete"
                    addClass="admin-translation_item__btn __delete"
                    onClick={() => showConfirm(values.id)}
                    loading={loadingDelete}
                    disabled={loadingSubmit}
                  />
                )}
                <ButtonTransparent
                  type="button"
                  name="Cancel"
                  addClass="admin-translation_item__btn __cancel"
                  onClick={closeTransElementForm}
                  disabled={loadingSubmit}
                />
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    );
  })
);

export default FormTranslationElement;
