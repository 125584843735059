import React from "react";
import {Table} from "antd";
import {Link} from "react-router-dom";
import {toUser} from "../../../constants/routes.const";

const usersColumns = [
  {
    title: "ID",
    key: "id",
    dataIndex: "id",
    render: (id) => <Link to={toUser(id)}>{id.slice(0, 8)}</Link>,
  },
  {title: "Name", key: "name", dataIndex: "name", filtered: true},
  {title: "Email", key: "email", dataIndex: "email", filtered: true},
  {title: "Phone", key: "phone", dataIndex: "mobilePhone", filtered: true},
  {
    title: "Created (CET time)",
    key: "created",
    dataIndex: "createdAt",
    render: (date) => new Date(date).toLocaleString("sv"),
  },
];

const mapUsers = (user, i) => ({
  key: `key_${i}`,
  ...user,
  name: `${user.firstName} ${user.lastName}`,
});

export const UsersTable = ({users, ...props}) => {
  return (
    <Table
      rowKey="id"
      {...props}
      dataSource={users?.map(mapUsers)}
      columns={usersColumns}
    />
  );
};
