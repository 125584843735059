import React, {useCallback} from "react";
import axiosInstance from "../../axios";
import AdminLayout from "../_layout/AdminLayout";
import {Form, Input, Button, Row, Col} from "antd";
import {showSuccess, showError} from "helpers/notifications.helper";

const CreateAnyCompany = () => {
  const [updateEmployerDetailsOneCompanyForm] = Form.useForm();

  const onFinish = useCallback(
    async (values) => {
      try {
        await axiosInstance.post("admin/create-new-foreign-company", {
          ...values,
        });
        updateEmployerDetailsOneCompanyForm.resetFields();
        showSuccess("Company has been created", 7);
      } catch (e) {
        showError("Error");
      }
    },
    [updateEmployerDetailsOneCompanyForm]
  );

  const onReset = useCallback(() => {
    updateEmployerDetailsOneCompanyForm.resetFields();
  }, [updateEmployerDetailsOneCompanyForm]);

  return (
    <AdminLayout>
      <div className="content-box">
        <h1>Create any company</h1>
        <Form
          form={updateEmployerDetailsOneCompanyForm}
          layout="vertical"
          onFinish={onFinish}
        >
          <Row>
            <Col span={4}>
              <Form.Item
                label="Owner's first name"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please input owner's first name",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Owner's last name"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please input owner's last name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input an e-mail!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Owner mobile phone"
                name="mobilePhone"
                rules={[
                  {
                    required: true,
                    message: "Please input mobile phone",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Company number"
                name="companyNumber"
                rules={[
                  {
                    required: true,
                    message: "Please input company number",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Company name"
                name="companyName"
                rules={[
                  {
                    required: true,
                    message: "Please input company name",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Create
                </Button>

                <Button htmlType="button" onClick={onReset}>
                  Reset
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </AdminLayout>
  );
};

export default CreateAnyCompany;
