import React from "react";
import {Navigate} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {ADMIN_LOGIN} from "../constants/routes.const";

export const AdminRoute = inject("store")(
  observer(({store: {admin}, Component}) => {
    const {token} = admin;

    return token ? <Component /> : <Navigate to={ADMIN_LOGIN} />;
  })
);
