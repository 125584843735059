import {useApi} from "./useApi";
import axiosInstance from "../axios";

// ****** WORKING WITH USERS ******

export const useUsers = (
  {firstName = "", lastName = "", mobilePhone = "", email = ""},
  page,
  size = 20
) => {
  return useApi("admin/users/search", {
    method: "post",
    data: {firstName, lastName, mobilePhone, email, page, size},
  });
};

export const useUser = (id) => {
  return useApi(`admin/users/${id}`);
};

export const deleteUser = (userId) => {
  return axiosInstance.delete(`admin/users/${userId}`);
};

export const updateUser = (userId, body) => {
  return axiosInstance.post(`admin/users/${userId}/update`, body);
};

export const useSearchUsers = (
  {firstName = "", lastName = "", phone = "", email = ""},
  limit = 20
) => {
  return useApi("admin/users/search", {
    method: "post",
    data: {firstName, lastName, mobilePhone: phone, email, limit},
  });
};

export const setUserActive = (userId, isActive) => {
  return axiosInstance.post(`admin/users/${userId}/active`, {isActive});
};
