import React from "react";
import {useNavigate} from "react-router-dom";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";

const AdminHeader = ({adminUserInfo}) => {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  const {email, firstName, lastName} = adminUserInfo;
  return (
    <div className="admin_header__wrapper">
      <span className="admin_header__login __wrapper">{`${firstName} ${lastName} <${email}>`}</span>
      <button className="admin_header__login __wrapper" onClick={logout}></button>
      <ButtonTransparent name="Log out" onClick={logout} addClass="__danger" />
    </div>
  );
};

export default AdminHeader;
