import React, {useState, useCallback} from "react";
import {Button} from "antd";

import {addOrganizationLogo, removeOrganizationLogo} from "api/organizations";

import {Upload} from "antd";
import {beforeUploadPNGJPEGSVG} from "helpers/uploader";
import noLogo from "assets/images/No-logo.svg";
// import enIcon from "assets/images/svg/enIcon.svg";
import {acceptImages} from "constants/accept.consts";

export const OrganizationLogo = ({activeLogo, organizationId}) => {
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(activeLogo);

  const handleChange = (info) => info.file.status === "uploading" && setLoading(true);

  const onUpdateLogo = useCallback(
    ({file}) => {
      const data = new FormData();
      data.append("file", file);
      addOrganizationLogo({
        data,
        organizationId,
      })
        .then(({data}) => setLogo(data.url))
        .finally(() => setLoading(false));
    },
    [organizationId]
  );

  const onRemoveLogo = () =>
    removeOrganizationLogo(organizationId).then(() => setLogo(null));

  return (
    <div>
      <div className="df-row-center">
        <img src={logo ?? noLogo} alt="" height="80" />
        <Upload
          accept={acceptImages}
          beforeUpload={beforeUploadPNGJPEGSVG}
          onChange={handleChange}
          customRequest={onUpdateLogo}
          showUploadList={false}
        >
          <Button className="mr-4 ml-6" type="primary" loading={loading}>
            Upload
          </Button>
        </Upload>
        <Button danger type="primary" onClick={onRemoveLogo}>
          Remove
        </Button>
      </div>
    </div>
  );
};
