import React from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import AdminFormsLayout from "../../../components/AdminFormsLayout/AdminFormsLayout";
import {Steps} from "antd";
import {steps} from "./constants/AdminRegisterConsts";

const {Step} = Steps;

const AdminRegisterForm = inject("store")(
  observer(({store: {admin}}) => {
    const {t} = useTranslation();
    const current = admin.registerStep;

    return (
      <AdminFormsLayout
        addClass="admin_register-phone__wrapper"
        formTitle={t("REGISTER")}
      >
        <div className="admin_authentication__steps __wrapper">
          <Steps
            current={current}
            className="admin_authentication__steps __numbers inviteOnboarding_points__wrapper"
          >
            {steps.map((item, i) => (
              <Step key={i} />
            ))}
          </Steps>

          <div className="admin_authentication__steps __forms">
            {steps[current].content}
          </div>
        </div>
      </AdminFormsLayout>
    );
  })
);

export default AdminRegisterForm;
