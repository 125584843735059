import React from "react";
import {Spin} from "antd";

export const Preloader = ({addClass, size}) => {
  return (
    <div className={addClass || "preloader"}>
      <Spin size={size || "large"} />
    </div>
  );
};
