import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Form, Formik} from "formik";
import {schema, initialValues} from "./formAttrs";
import FormikField from "components/FormikField";
import AdminFormsLayout from "../../../components/AdminFormsLayout/AdminFormsLayout";
import {Button} from "components/buttons/Button";
import {inject, observer} from "mobx-react";
import {showSuccess} from "helpers/notifications.helper";
import {ADMIN_CHANGE_PASS_DONE} from "../../../constants/routes.const";

const AdminChangePassPage = inject("store")(
  observer(({store: {admin}}) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const query = new URLSearchParams(window.location.search);
    const token = query.get("token");

    const onSubmit = (values) => {
      setLoading(true);
      admin
        .resetAdminPass(Object.assign(values, {token}))
        .then(() => {
          showSuccess("Successfully submitted!");
          navigate(ADMIN_CHANGE_PASS_DONE);
        })
        .finally(() => setLoading(false));
    };

    const validateConfirmPassword = (pass, value) => {
      let error = "";
      if (pass && value) {
        if (pass !== value) {
          error = "Password not matched";
        }
      }
      return error;
    };

    return (
      <AdminFormsLayout
        addClass="admin_change-pass__wrapper"
        formTitle={t("LOGIN_CHANGE_PASSWORD")}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({values, dirty, isValid}) => (
            <Form>
              <FormikField
                type="password"
                label={t("LOGIN_NEW_PASSWORD")}
                name="password"
                errorName="password"
                placeholder={t("LOGIN_PLACEHOLDER_NEW_PASSWORD")}
              />
              <FormikField
                type="password"
                label={t("LOGIN_REPEAT_NEW_PASSWORD")}
                name="confirmPassword"
                errorName="confirmPassword"
                placeholder={t("LOGIN_PLACEHOLDER_REPEAT_NEW_PASSWORD")}
                validate={(value) => validateConfirmPassword(values.password, value)}
              />
              <div className="admin_authentication__form __actions">
                <Button
                  type="submit"
                  loading={loading}
                  name={t("LOGIN_CHANGE_PASSWORD")}
                  disabled={!dirty || !isValid}
                />
              </div>
            </Form>
          )}
        </Formik>
      </AdminFormsLayout>
    );
  })
);

export default AdminChangePassPage;
