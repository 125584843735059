import {useApi} from "./useApi";
import axiosInstance from "../axios";
import moment from "moment";

// ****** WORKING WITH ORGANIZATION ******

export const useOrganizations = (
  name = "",
  description = "",
  customerId = null,
  SubscribeProductId = "",
  page = 1,
  size = 20
) =>
  useApi("admin/organizations/search", {
    method: "post",
    data: {name, description, customerId, SubscribeProductId, page, size},
  });

export const useOrganization = (id) => useApi(`admin/organizations/${id}`);

export const updateOrganization = (organizationId, values) =>
  axiosInstance.post(`admin/organizations/${organizationId}`, values);

export const addUserToOrganization = ({obj, organizationId}) =>
  axiosInstance.post("admin/users/add-user-to-org", {
    ...obj,
    organizationId,
  });

export const findExistingUser = (obj) =>
  axiosInstance.post("admin/users/search-existing-user", {
    ...obj,
  });

export const addExistingUserToOrg = (obj) =>
  axiosInstance.post("admin/users/add-existing-user", {
    ...obj,
  });

export const addOrganizationLogo = ({data, organizationId}) =>
  axiosInstance.post(`admin/organizations/${organizationId}/header-logo/upload`, data);

export const removeOrganizationLogo = (organizationId) =>
  axiosInstance.delete(`admin/organizations/${organizationId}/header-logo`);

// ****** WORKING WITH SUBSCRIPTION AND PERMISSIONS ******

export const useSubscriptionOrders = ({
  subscribeProductNames,
  name = null,
  fromDate = "2020-01-01",
  toDate = moment().format("YYYY-MM-DD"),
  page = 1,
  pageSize = 20,
}) =>
  useApi("admin/subscribes/orders/search", {
    method: "post",
    data: {subscribeProductNames, name, fromDate, toDate, page, pageSize},
  });

export const setSubscription = (id, values) =>
  axiosInstance.post(`admin/organizations/${id}/start-subscribe`, values);

export const updateSubscribeProduct = (subscriptionId, values) =>
  axiosInstance.post(`admin/permissions/subscribe-product/${subscriptionId}`, values);

export const updateSubscribeRole = (subscriptionRoleId, values) =>
  axiosInstance.post(`admin/permissions/subscribe-role/${subscriptionRoleId}`, values);

export const downloadSubscriptionCSV = (obj) =>
  axiosInstance.post("admin/subscribes/reportPeriodCSV", obj);

export const usePermissionsGroups = () => useApi("admin/permissions/groups-filled");

export const setSubscriptionOnboardingAC = (id, values) =>
  axiosInstance.post(
    `admin/organizations/${id}/active-compaign-subscribe-started`,
    values
  );

// ****** WORKING WITH STATUSES ******

export const useStatuses = () => useApi("admin/organizations/fetch-statuses");

export const setStatus = (organizationId, statusName) =>
  axiosInstance.post("admin/organizations/update-status", {
    organizationId,
    statusName,
  });

// ****** WORKING WITH DALUX ******

export const setDaluxPossibility = (organizationId, enableDaluxIntegration) =>
  axiosInstance.post(`admin/organizations/${organizationId}/update-dalux`, {
    enableDaluxIntegration,
  });

// ****** WORKING WITH CREDIT REPORT COMPANIES ******

export const setCreditReportPossibility = (organizationId, enableCreditReportCompanies) =>
  axiosInstance.post(
    `admin/organizations/${organizationId}/update-credit-report-companies`,
    {
      enableCreditReportCompanies,
    }
  );

// ****** WORKING WITH CS Monitoring ******

export const setCSMonitoringPossibility = (organizationId, allowMonitorSuppliers) =>
  axiosInstance.post(`admin/organizations/${organizationId}/update-monitor-suppliers`, {
    allowMonitorSuppliers,
  });

// ****** WORKING WITH NOTES ******

export const setNotes = (organizationId, note) =>
  axiosInstance.post("admin/organizations/update-notes", {
    organizationId,
    note,
  });

// ****** WORKING WITH RFP ******

export const useRFPBuyerRequest = (organizationId) =>
  useApi(`admin/organizations/${organizationId}/rfp/buyer/requests`);

export const useRFPSellerProposals = (organizationId) =>
  useApi(`admin/organizations/${organizationId}/rfp/seller/proposals`);

export const useRFPOffers = (organizationId) => {
  return useApi(`admin/organizations/${organizationId}/rfp/seller/offers/`);
};

export const downloadOpenProcurementsLogCSV = (obj) =>
  axiosInstance.post("admin/rft/report-open-rft-csv", obj);

// ****** WORKING WITH USERS ******

export const removeUser = (organizationId, userId) => {
  return axiosInstance.delete(`admin/organizations/${organizationId}/users/${userId}`);
};

export const changeUserRole = (organizationId, userId, role) => {
  return axiosInstance.post(
    `admin/organizations/${organizationId}/users/${userId}/role`,
    {role}
  );
};

export const changeUserSubscriptionRole = (organizationId, userId, SubscribeRoleId) => {
  return axiosInstance.post(
    `admin/organizations/${organizationId}/users/${userId}/subscribe-role`,
    {SubscribeRoleId}
  );
};

export const inviteUsers = (
  organizationId,
  {firstName, lastName, file, inviteByPhone, inviteByEmail, directInviteMessage}
) => {
  const formData = new FormData();

  formData.append("file", file);
  formData.append("firstName", firstName);
  formData.append("lastName", lastName);
  formData.append("inviteByPhone", inviteByPhone);
  formData.append("inviteByEmail", inviteByEmail);
  formData.append("directInviteMessage", directInviteMessage);

  return axiosInstance.post(`admin/organizations/${organizationId}/invite`, formData, {
    headers: {"Content-Type": "multipart/form-data"},
  });
};
