import React from "react";

const TranslationListHeader = () => {
  return (
    <div className="admin-translation_item__wrapper __header">
      <div className="admin-translation_item__inner-wrapper">
        <div className="admin-translation_item__text">Key</div>
        <div className="admin-translation_item__text">English</div>
        <div className="admin-translation_item__text">Swedish</div>
        <div className="admin-translation_item__text">Finnish</div>
        <div className="admin-translation_item__action">Action</div>
      </div>
    </div>
  );
};

export default TranslationListHeader;
