import React, {useCallback} from "react";
import {Link, useParams} from "react-router-dom";
import {Descriptions, Skeleton, Button} from "antd";
// import {Popover} from "antd";
import AdminLayout from "../_layout/AdminLayout";
import {toOrganization} from "../../constants/routes.const";
import {
  useCompany,
  // useDeleteCompany,
  useUpdateAllaBolag,
  useUpdateCreditSafeBasic,
  useUpdateCreditSafeCredit,
  useUpdateBygginstsektornCompany,
  useUpdateKollektivavtalskollenCompany,
} from "../../api/companies";

// import {ConfirmModal} from "components/ConfirmModal";
// import {useTranslation} from "react-i18next";

const renderOrganizations = (company) => {
  return company.Organizations.map((organization) => {
    return (
      <React.Fragment key={organization.id}>
        <Link to={toOrganization(organization.id)}>{organization.name}</Link>
        <br />
      </React.Fragment>
    );
  });
};

const renderEmployerContributions = (company) => {
  const obj = company.EmployerTaxDetails[0].employerContributions;
  return Object.keys(obj).map((key, i) => {
    return (
      <React.Fragment key={i}>
        <div>
          {key} &nbsp; - &nbsp; {obj[key]}
        </div>
      </React.Fragment>
    );
  });
};

const CompanyPage = () => {
  const {id} = useParams();
  // const navigate = useNavigate();
  // const {t} = useTranslation();
  const [, company, loading, companyRequest] = useCompany(id);
  // const [, , deleteLoading, request] = useDeleteCompany(id);

  const [, , updateAllaBolagLoading, updateAllaBolagRequest] = useUpdateAllaBolag(id);

  const [, , updateCreditSafeBasicLoading, updateCreditSafeBasicRequest] =
    useUpdateCreditSafeBasic(id);
  const [, , updateCreditSafeCreditLoading, updateCreditSafeCreditRequest] =
    useUpdateCreditSafeCredit(id);
  const [, , updateBygginstsektornLoading, updateBygginstsektornRequest] =
    useUpdateBygginstsektornCompany(id);
  const [, , updateKollektivavtalskollenLoading, updateKollektivavtalskollenRequest] =
    useUpdateKollektivavtalskollenCompany(id);

  // const clickDelete = () =>
  //   ConfirmModal({
  //     title: t("WANT_DELETE"),
  //     text: t("CANT_DELETE_COMPANY"),
  //     type: "warning",
  //     onOk: onDelete,
  //     onCancel: () => {},
  //   });

  // const onDelete = useCallback(() => {
  //   request().then(() => navigate("/companies"));
  // }, [navigate, request]);

  const onUpdateAllaBolag = useCallback(() => {
    updateAllaBolagRequest().then(() => companyRequest());
  }, [companyRequest, updateAllaBolagRequest]);

  const onUpdateCreditSafeBasic = useCallback(() => {
    updateCreditSafeBasicRequest().then(() => companyRequest());
  }, [companyRequest, updateCreditSafeBasicRequest]);

  const onUpdateCreditSafeCredit = useCallback(() => {
    updateCreditSafeCreditRequest().then(() => companyRequest());
  }, [companyRequest, updateCreditSafeCreditRequest]);

  const onUpdateBygginstsektorn = useCallback(() => {
    updateBygginstsektornRequest().then(() => companyRequest());
  }, [companyRequest, updateBygginstsektornRequest]);

  const onUpdateKollektivavtalskollen = useCallback(() => {
    updateKollektivavtalskollenRequest().then(() => companyRequest());
  }, [companyRequest, updateKollektivavtalskollenRequest]);

  return (
    <AdminLayout>
      <div className="content-box">
        {loading && <Skeleton active />}
        {company && (
          <>
            <Descriptions
              size={"small"}
              title={<h1>{`${company.companyName}`}</h1>}
              bordered
              column={1}
              labelStyle={{width: 200}}
              extra={
                <>
                  {/* <Button
                    type="danger"
                    onClick={clickDelete}
                    loading={deleteLoading}
                    className="mr-4"
                  >
                    Delete
                  </Button> */}
                  <Button
                    type="primary"
                    className="mr-2"
                    onClick={onUpdateCreditSafeBasic}
                    loading={updateCreditSafeBasicLoading}
                  >
                    CS Basic
                  </Button>
                  <Button
                    type="primary"
                    className="mr-2"
                    onClick={onUpdateCreditSafeCredit}
                    loading={updateCreditSafeCreditLoading}
                  >
                    CS Credit
                  </Button>
                  <Button
                    type="primary"
                    className="mr-2"
                    onClick={onUpdateBygginstsektorn}
                    loading={updateBygginstsektornLoading}
                  >
                    Bygginstsektorn
                  </Button>
                  <Button
                    type="primary"
                    className="mr-2"
                    onClick={onUpdateKollektivavtalskollen}
                    loading={updateKollektivavtalskollenLoading}
                  >
                    Kollektivavtal
                  </Button>
                  <Button
                    type="primary"
                    className="mr-2"
                    onClick={onUpdateAllaBolag}
                    loading={updateAllaBolagLoading}
                  >
                    Omsätning/Allabolag
                  </Button>
                </>
              }
            >
              <Descriptions.Item label="ID">{company.id}</Descriptions.Item>
              <Descriptions.Item label="Number">
                {company.companyNumber}
              </Descriptions.Item>
              <Descriptions.Item label="Employers Registration">
                {company.employersRegistration ? "YES" : "NO"}
              </Descriptions.Item>
              <Descriptions.Item label="Tax">
                {company.swedish_f_tax ? "YES" : "NO"}
              </Descriptions.Item>
              <Descriptions.Item label="VAT">
                {company.swedish_vat_registration ? "YES" : "NO"}
              </Descriptions.Item>
              <Descriptions.Item label="Credit Rating">
                {company.creditRating}
              </Descriptions.Item>
              <Descriptions.Item label="Turnover">{company.turnover}</Descriptions.Item>
              <Descriptions.Item label="Created (CET time)">
                {new Date(company.createdAt).toLocaleString("sv")}
              </Descriptions.Item>

              <Descriptions.Item label="CreditSafe BASIC updated (CET time)">
                {company.updatedCreditSafeBasic
                  ? new Date(company.updatedCreditSafeBasic).toLocaleString("sv")
                  : null}
              </Descriptions.Item>
              <Descriptions.Item label="CreditSafe CREDIT updated (CET time)">
                {company.updatedCreditSafeCredit
                  ? new Date(company.updatedCreditSafeCredit).toLocaleString("sv")
                  : null}
              </Descriptions.Item>
              <Descriptions.Item label="Bygginstsektorn updated (CET time)">
                {company.updatedBygginstsektornAt
                  ? new Date(company.updatedBygginstsektornAt).toLocaleString("sv")
                  : null}
              </Descriptions.Item>
              <Descriptions.Item label="Kollektivavtal updated (CET time)">
                {company.updatedKollektivavtalskollenAt
                  ? new Date(company.updatedKollektivavtalskollenAt).toLocaleString("sv")
                  : null}
              </Descriptions.Item>
              <Descriptions.Item label="Allabolag(omsätning) updated (CET time)">
                {company.updatedAllaBolag
                  ? new Date(company.updatedAllaBolag).toLocaleString("sv")
                  : null}
              </Descriptions.Item>
              <Descriptions.Item label="Organizations">
                {renderOrganizations(company)}
              </Descriptions.Item>
            </Descriptions>
            <br />

            {company.EmployerTaxDetails && !!company.EmployerTaxDetails.length && (
              <Descriptions
                size={"small"}
                title={<h1>Företagsinformation från Skatteverket</h1>}
                bordered
                column={1}
                labelStyle={{width: 200}}
              >
                <Descriptions.Item label="Organisationsnummer">
                  {company.EmployerTaxDetails[0].taxOrgNumber}
                </Descriptions.Item>
                <Descriptions.Item label="Namn">
                  {company.EmployerTaxDetails[0].companyName}
                </Descriptions.Item>
                <Descriptions.Item label="Godkänd för F-skatt">
                  {company.EmployerTaxDetails[0].fTax}
                </Descriptions.Item>
                <Descriptions.Item label="Registrerad för moms">
                  {company.EmployerTaxDetails[0].vat}
                </Descriptions.Item>
                <Descriptions.Item label="Registrerad som arbetsgivare">
                  {company.EmployerTaxDetails[0].registeredEmployer}
                </Descriptions.Item>
                <Descriptions.Item label="Beslutade arbetsgivaravgifter">
                  {renderEmployerContributions(company)}
                </Descriptions.Item>
                <Descriptions.Item label="Updated (CET time)">
                  {new Date(company.EmployerTaxDetails[0].createdAt).toLocaleString("sv")}
                </Descriptions.Item>
              </Descriptions>
            )}
          </>
        )}
      </div>
    </AdminLayout>
  );
};

export default CompanyPage;
