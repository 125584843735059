import React from "react";
import {Language} from "../../components/Language";

const AdminFooter = () => {
  return (
    <div className="admin_footer__wrapper">
      <Language />
    </div>
  );
};

export default AdminFooter;
