import React from "react";
import {Link} from "react-router-dom";
import {MAIN_PAGE} from "constants/routes.const";
import BrandLogo from "assets/images/svg/handy-day.svg";
import {Language} from "../Language";

const SubPageHeader = ({addClass}) => {
  return (
    <div className={`sub-page__header __wrapper ${addClass}`}>
      <Link to={MAIN_PAGE} href="#">
        <img src={BrandLogo} className="sub-page__header __brand" alt="brand logo" />
      </Link>
      <Language />
    </div>
  );
};

export default SubPageHeader;
