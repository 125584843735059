import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import HttpApi from "i18next-http-backend";
// locale
import axiosInstance from "../axios";

const TRANSLATE = i18next;

i18next
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    lng: "sv",
    fallbackLng: "sv",
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    backend: {
      loadPath: `locales/{{lng}}`,
      parse: (data) => {
        return data;
      },
      request: (options, url, payload, callback) => {
        axiosInstance
          .get(url)
          .then((res) => {
            callback(null, res);
          })
          .catch((err) => {
            callback(err, null);
          });
      },
    },
  });

export default TRANSLATE;
