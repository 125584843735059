import React, {memo} from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export const ReactNumberInput = memo(({phone, setPhone, onEnterKeyPress, setIsValid}) => {
  const handleValid = (value, country) => {
    const regexp = /^0/;
    const phoneWithOutCountryCode = value.substring(country.countryCode.length);
    if (phoneWithOutCountryCode.match(regexp)) {
      setIsValid(false);
      return "Phone number is invalid: " + value + ", " + country.name;
    }
    setIsValid(true);
    return true;
  };

  return (
    <PhoneInput
      country={"se"}
      // preferredCountries={["se"]}
      onlyCountries={["se", "es"]} // might be added later "lt", "pl", "ee", "lv", "no", "dk", "fi"
      value={phone}
      onChange={setPhone}
      containerClass="react-number-input"
      inputClass="react-number-input-search"
      buttonClass="react-number-btn"
      autoFocus={false}
      onEnterKeyPress={onEnterKeyPress}
      autocompleteSearch={true}
      masks={{se: "(..) ...-..-..", es: "...-...-..."}}
      preserveOrder={["onlyCountries", "preferredCountries"]}
      isValid={(value, country) => handleValid(value, country)}
      enableAreaCodeStretch
    />
  );
});
