import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import {Checkbox} from "antd";
// local
import AdminLayout from "../_layout/AdminLayout";
import {
  usePermissionsGroups,
  updateSubscribeProduct,
  updateSubscribeRole,
} from "../../api/organizations";
import {PermissionsTable} from "./component/PermissionsTable";
import {showSuccess} from "helpers/notifications.helper";

const PermissionsPage = inject("store")(
  observer(({store: {admin}}) => {
    const {subscriptions, subscriptionRoles} = admin;
    const {t} = useTranslation();
    const [showRoles, setShowRoles] = useState(true);
    const [loading, setLoading] = useState(false);
    const [changeLoading, setChangeLoading] = useState([]);
    const [, permissions, permissionsLoading] = usePermissionsGroups();

    const subscriptionsRoleSubColumns = useMemo(
      () =>
        subscriptionRoles?.reduce(
          (obj, item) =>
            Object.assign(
              obj,
              item.SubscribeProductLinkedNames.reduce((objName, name) => {
                const role = {
                  title: `${t(name)} (${t(item.name).toUpperCase()})`,
                  key: item.id,
                  dataIndex: item.id,
                  className: "subscription-column",
                };
                return Object.assign(objName, {
                  [name]: obj[name] ? [...obj[name], role] : [role],
                });
              }, {})
            ),
          {}
        ),
      [subscriptionRoles, t]
    );

    const subscriptionColumns = useMemo(
      () =>
        subscriptions?.reduce((columns, subscription) => {
          return [
            ...columns,
            {
              title: `${t(subscription?.name)} (${subscription?.name.slice(-2)})`,
              key: subscription?.name,
              dataIndex: subscription?.name,
              className: "subscription-column",
            },
            ...(showRoles ? subscriptionsRoleSubColumns[subscription?.name] ?? [] : []),
          ];
        }, []),
      [subscriptions, t, subscriptionsRoleSubColumns, showRoles]
    );

    const preparedPermissions = useMemo(
      () =>
        permissions
          ? permissions[0].Permissions.map((permission) => ({
              ...permission,
              ...subscriptions?.reduce(
                (acc, subscription) =>
                  Object.assign(acc, {
                    [subscription.name]: {
                      checked: subscription.SubscribeProductPermissions.some(
                        (item) => item.PermissionId === permission.id
                      ),
                      disabled: subscriptionRoles?.some(
                        (subRole) =>
                          subRole.SubscribeProductLinkedNames.includes(
                            subscription.name
                          ) &&
                          subRole.SubscribeRolePermissions?.some(
                            (item) => item.PermissionId === permission.id
                          )
                      ),
                      quantity:
                        subscription.SubscribeProductPermissions.find(
                          (item) => item.PermissionId === permission.id
                        )?.quantity ?? null,
                      subscriptionId: subscription.id,
                    },
                  }),
                {}
              ),
              ...subscriptionRoles?.reduce(
                (acc, role) =>
                  Object.assign(acc, {
                    [role.id]: {
                      checked: role.SubscribeRolePermissions?.some(
                        (item) => item.PermissionId === permission.id
                      ),
                      disabled: subscriptions
                        ?.find((sub) =>
                          role.SubscribeProductLinkedNames.includes(sub.name)
                        )
                        .SubscribeProductPermissions.some(
                          (item) => item.PermissionId === permission.id
                        ),
                      quantity:
                        role.SubscribeRolePermissions?.find(
                          (item) => item.PermissionId === permission.id
                        )?.quantity ?? null,
                      subscriptionId: role.id,
                    },
                  }),
                {}
              ),
            }))
          : [],
      [permissions, subscriptions, subscriptionRoles]
    );

    const handlePermissionChange = (subscriptionId, permissionId, checked, quantity) => {
      setChangeLoading([subscriptionId, permissionId]);
      const isSubscriptionRole = subscriptionId.includes("USER_ROLE_SUB");
      const currentPermissions =
        (isSubscriptionRole ? subscriptionRoles : subscriptions)
          .find((subscription) => subscription.id === subscriptionId)
          [
            isSubscriptionRole
              ? "SubscribeRolePermissions"
              : "SubscribeProductPermissions"
          ]?.filter((permission) => permission.PermissionId !== permissionId) ?? [];

      const payloadPermissions = checked
        ? currentPermissions.concat({
            PermissionId: permissionId,
            quantity,
          })
        : currentPermissions;
      (isSubscriptionRole ? updateSubscribeRole : updateSubscribeProduct)(
        subscriptionId,
        payloadPermissions
      )
        .then(() => {
          setLoading(true);
          admin
            .fetchSubscriptionsInfo()
            .then(() => setChangeLoading([]))
            .finally(() => setLoading(false));
          showSuccess();
        })
        .catch(() => setChangeLoading([]));
    };

    return (
      <AdminLayout>
        <div className="content-box">
          <div className="df-row-jsb-center">
            <h1>Subscription permissions</h1>
            <Checkbox
              checked={showRoles}
              disabled={false}
              onChange={(e) => setShowRoles(e.target.checked)}
            >
              Show subscription roles
            </Checkbox>
          </div>

          <PermissionsTable
            subscriptionColumns={subscriptionColumns}
            loading={loading || permissionsLoading}
            checkLoading={changeLoading}
            dataSource={preparedPermissions}
            onChange={handlePermissionChange}
            idKey="subscriptionId"
            pagination={false}
            needScroll={showRoles}
          />
        </div>
      </AdminLayout>
    );
  })
);

export default PermissionsPage;
