import React, {useState} from "react";
import ButtonEdit from "components/buttons/ButtonEdit";
import FormTranslationElement from "../FormTranslationElement/FormTranslationElement";
import {copyToClipboard} from "helpers/copyToClipboard";

const TranslationElement = ({transValues}) => {
  const [edit, setEdit] = useState(false);
  const openEdit = () => setEdit(true);
  const closeEdit = () => setEdit(false);
  const values = ["key", "en", "sv", "fi"];

  const fields = values.map((value) => (
    <div className="admin-translation_item__text" key={value}>
      <span onClick={() => copyToClipboard(transValues[value])}>
        {transValues[value]}
      </span>
    </div>
  ));

  if (edit) {
    return (
      <FormTranslationElement values={transValues} isEditElement closeEdit={closeEdit} />
    );
  }

  return (
    <div className="admin-translation_item__wrapper">
      <div className="admin-translation_item__inner-wrapper">
        {fields}
        <div className="admin-translation_item__action">
          <ButtonEdit onClick={openEdit} />
        </div>
      </div>
    </div>
  );
};

export default TranslationElement;
