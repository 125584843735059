import React, {useCallback, useEffect, useRef} from "react";
import {Input} from "components/Input";
import debounce from "lodash.debounce";
import {useNavigate} from "react-router-dom";
import {createBrowserHistory} from "history";
import {inject, observer} from "mobx-react";

const SearchTranslations = inject("store")(
  observer(({store: {admin}}) => {
    const inputRef = useRef();
    const searchKey = "search";
    const history = createBrowserHistory();
    const navigate = useNavigate();
    const debounceTime = 400;

    const onSelectChangeHandler = (search) => {
      const params = {...admin.translationFilterParams, search: search.trim()};
      // if this empty string block http requests
      if (search.length && !search.trim().length) {
        return;
      }
      navigate({search: new URLSearchParams(params).toString()});
      getTranslations(params, 1);
    };

    const inputDebounce = debounce(() => {
      const {value} = inputRef.current;
      onSelectChangeHandler(value);
    }, debounceTime);

    const getKeyFromQuery = useCallback(
      (value) => new URLSearchParams(history.location.search).get(value),
      [history.location.search]
    );

    const getTranslations = useCallback(
      (params, page) => {
        admin.setTranslationFilterParams(params);
        admin.getTranslations(page);
      },
      [admin]
    );

    useEffect(() => {
      const search = getKeyFromQuery(searchKey);
      const params = {...admin.translationFilterParams, search};
      getTranslations(params);
    }, [admin, getKeyFromQuery, getTranslations]);

    return (
      <Input
        focus={inputRef}
        onChange={inputDebounce}
        placeholder="Find translation"
        defaultValue={getKeyFromQuery(searchKey)}
      />
    );
  })
);

export default SearchTranslations;
