import React from "react";
import {ButtonTransparent} from "../../../../components/buttons/ButtonTransparent";
import AddIcon from "../../../../assets/images/svg/plus.svg";
import Ellipsis from "../../../../assets/images/svg/ellipsis.svg";
import {inject, observer} from "mobx-react";
import UploadTranslationsBase from "./components/UploadTranslationsBase";
import DownloadTranslationsBase from "./components/DownloadTranslationsBase";
import SearchTranslations from "./components/SearchTranslations";

const AdminTranslationsHeader = inject("store")(
  observer(({store: {admin}}) => {
    return (
      <div className="admin-translation_head">
        <div className="admin-translation_head__left">
          <DownloadTranslationsBase />
          <UploadTranslationsBase />
        </div>
        <div className="admin-translation_head__center">
          <SearchTranslations />
        </div>
        <div className="admin-translation_head__right">
          <ButtonTransparent
            name="Add new translation"
            type="button"
            img={admin.isAddNewTransFormOpen ? Ellipsis : AddIcon}
            onClick={() => admin.setOpenNewTranslationForm()}
          />
        </div>
      </div>
    );
  })
);

export default AdminTranslationsHeader;
