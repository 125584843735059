import React, {useState} from "react";
import {Button, Select} from "antd";
import {useTranslation} from "react-i18next";

export const Status = ({activeStatus, statuses = [], regionsCount, submitStatus}) => {
  const {Option} = Select;
  const [status, setStatus] = useState(activeStatus);
  const {t} = useTranslation();

  return (
    <div>
      <div className="df-row-center">
        <Select
          className="mr-4"
          style={{width: "200px"}}
          value={status}
          onChange={setStatus}
          placeholder="Select status"
          key={"name"}
        >
          {!!statuses?.length &&
            statuses.map((item) => (
              <Option key={item.name} value={item.name}>
                {t(item.name)}
              </Option>
            ))}
        </Select>
        <Button type="primary" onClick={() => submitStatus(status)}>
          Set status
        </Button>
        <span className="ml-20">{`Regions count: ${t(regionsCount) ?? 0}`}</span>
      </div>
    </div>
  );
};
