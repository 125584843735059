export const MAIN_PAGE = "/";

export const OUR_PROFILE = "/our-profile";
export const REQUESTS = "/requests";
export const REQUEST = "/requests/:requestId";
export const CONTRACTS = "/contracts";
export const ONBOARDING = "/onboarding";
export const SAVE_SEARCH = "/save-search-preference";
export const toOrganizationId = (id) => `/organizations/${id}`;
export const toRequestId = (id) => `/requests/${id}`;
export const toRequestToUsId = (id) => `/requests-to-us/${id}`;
export const ORGANIZATION_USER_INVITE_CHOICE = "/accept-organization-invite/choice";
//Admin-pages
export const COMMON_SETTINGS = "/settings";
export const OPEN_RFPS = "/open-rfps";

export const ADMIN = "/";
export const ADMIN_TRANSLATIONS = "/translations";
export const ADMIN_RESET_PASS_DONE = "/reset-password-done";
export const ADMIN_CHANGE_PASS_DONE = "/change-password-done";

export const USERS = "/users";
export const USER = "/user/:id";
export const toUser = (id) => `/user/${id}`;

export const ORGANIZATIONS = "/organizations";
export const ORGANIZATION = "/organization/:id";
export const toOrganization = (id) => `/organization/${id}`;

export const SUBSCRIPTION_ORDERS = "/subscription-orders";
export const PERMISSIONS = "/permissions";

export const ADVERTISEMENTS = "/advertisements";

export const APPLYING_USER = "/applying-users";
export const ORGSNOBANKID = "/org-no-bank-id";
export const CREATE_ANY_COMPANY = "/create-any-company";

export const COMPANIES = "/companies";
export const COMPANY = "/company/:id";
export const toCompany = (id) => `/company/${id}`;

//-forms
export const ADMIN_LOGIN = "/login";
export const ADMIN_RESET_PASS = "/reset-password";
export const ADMIN_CHANGE_PASS = "/change-password";
export const ADMIN_REGISTER = "/register";
