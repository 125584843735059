import React from "react";
import {Table} from "antd";
import {Link} from "react-router-dom";
import {toUser, toOrganization} from "../../constants/routes.const";

const usersColumns = [
  {
    title: "UserId",
    key: "id",
    dataIndex: "id",
    render: (id) => <Link to={toUser(id)}>{id.slice(0, 8)}</Link>,
  },
  {title: "Name", key: "name", dataIndex: "name", filtered: true},
  {title: "Email", key: "email", dataIndex: "email", filtered: true},
  {title: "Phone", key: "phone", dataIndex: "mobilePhone", filtered: true},
  {
    title: "Want to Join Organization",
    key: "id",
    render: (obj) => {
      return <Link to={toOrganization(obj.OrgId)}>{obj?.JoinRequest[0].name}</Link>;
    },
  },
];

const mapUsers = (user, i) => ({
  key: i,
  ...user,
  name: `${user.firstName} ${user.lastName}`,
  OrgId: `${user.JoinRequest[0].id}`,
});

export const UsersTable = ({users, ...props}) => {
  return (
    <Table
      rowKey="id"
      {...props}
      dataSource={users?.map(mapUsers)}
      columns={usersColumns}
    />
  );
};
