import React, {useState} from "react";
import {Button} from "antd";
import {useTranslation} from "react-i18next";
// local
import {ConfirmModal} from "components/ConfirmModal";

export const CSMonitoring = ({
  activeStatus,
  submitStatus,
  companiesCount,
  usersCount,
  loading,
}) => {
  const {t} = useTranslation();

  const [status, setStatus] = useState(activeStatus);

  const changeStatus = () => {
    setStatus(!status);
    submitStatus(!status);
  };

  const handleChangeStatus = () =>
    status
      ? ConfirmModal({
          title: t("WANT_DISABLE"),
          text: t("CANT_DISABLE_MONITOR_SUPPLIERS"),
          type: "warning",
          onOk: changeStatus,
          onCancel: () => {},
        })
      : changeStatus();

  return (
    <div>
      <div className="df-row-center">
        <span className="__bold mr-4">{status ? "ENABLED" : "DISABLED"}</span>
        <Button type="primary" onClick={handleChangeStatus} loading={loading}>
          Change
        </Button>
        {status && (
          <span className="ml-4">
            {companiesCount ?? 0} companies monitored by {usersCount ?? 0} users
          </span>
        )}
      </div>
    </div>
  );
};
