import React, {useState, useEffect} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {schema, initialValues} from "./formAttrs";
import {Form, Formik} from "formik";
import FormikField from "components/FormikField";
import {ADMIN, ADMIN_REGISTER, ADMIN_RESET_PASS} from "../../../constants/routes.const";
import {Link} from "react-router-dom";
import AdminFormsLayout from "../../../components/AdminFormsLayout/AdminFormsLayout";
import {Button} from "components/buttons/Button";
import {useNavigate} from "react-router-dom";
import {showError, showSuccess} from "helpers/notifications.helper";

const AdminLoginPage = inject("store")(
  observer(({store: {admin}}) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
      if (admin?.token) navigate(ADMIN);
    }, [admin?.token, navigate]);

    const onSubmit = async (values) => {
      try {
        setLoading(true);
        await admin.checkAdminLogin(values);
        showSuccess("Successfully submitted!");
        navigate(ADMIN);
        await admin.fetchAdminUserInfo();
        setLoading(false);
      } catch (error) {
        setLoading(false);
        showError("Email or password not correct!");
      }
    };

    return (
      <AdminFormsLayout addClass="admin_login__wrapper" formTitle={t("LOGIN")}>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({isValid, dirty}) => (
            <Form>
              <FormikField
                label={t("E_MAIL")}
                name="email"
                errorName="email"
                placeholder={t("LOGIN_PLACEHOLDER_EMAIL")}
                className="admin_authentication__form __field"
              />
              <FormikField
                type="password"
                label={t("LOGIN_PASSWORD")}
                name="password"
                errorName="password"
                placeholder={t("LOGIN_PLACEHOLDER_PASSWORD")}
                className="admin_authentication__form __field"
              />
              <div className="admin_authentication__form __actions">
                <Button
                  type="submit"
                  loading={loading}
                  name={t("LOGIN")}
                  disabled={!dirty || !isValid}
                />
              </div>
            </Form>
          )}
        </Formik>
        <div className="admin_authentication__link __wrapper">
          <Link to={ADMIN_RESET_PASS}>{t("LOGIN_FORGOT_PASSWORD")}</Link>
          <span>
            {t("LOGIN_FIRST_TIME_HERE")} <Link to={ADMIN_REGISTER}>{t("REGISTER")}</Link>
          </span>
        </div>
      </AdminFormsLayout>
    );
  })
);

export default AdminLoginPage;
