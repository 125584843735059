import {useApi} from "./useApi";
import axiosInstance from "../axios";

// ****** WORKING WITH COMMON SETTINGS ******

export const useCommonSettings = () => useApi("admin/common-settings");

export const updateInfoStripSetting = (values) => {
  return axiosInstance.put("admin/common-settings/info-strip", values);
};

export const useAdvertisements = (page = 1, size = 10) =>
  useApi("admin/advertisement/search", {params: {page, size}});

export const useDeleteAdvertisement = (id) =>
  useApi(`admin/advertisement/${id}`, {method: "delete", autoRequest: false});

export const createAdvertisement = (values) =>
  axiosInstance.post("admin/advertisement", values);

export const updateAdvertisement = (id, values) =>
  axiosInstance.put(`admin/advertisement/${id}`, values);

export const deleteAdvertisement = (id) =>
  axiosInstance.delete(`admin/advertisement/${id}`);

export const uploadFileAdvertisement = (id, data) =>
  axiosInstance.post(`admin/advertisement/${id}/upload`, data);
