import React from "react";
import {Table} from "antd";
import {defaultTableColumns} from "../../helpers/defaultTableColumns";
import {Link} from "react-router-dom";
import {toCompany, toOrganization} from "../../constants/routes.const";
import {useTranslation} from "react-i18next";

export const OrganizationsTable = (props) => {
  const {loading, dataSource, pagination, style} = props;
  const {t} = useTranslation();

  const columns = [
    {
      title: "ID",
      key: "id",
      dataIndex: "id",
      render: (id) => <Link to={toOrganization(id)}>{id.slice(0, 8)}</Link>,
    },
    {
      title: "Company",
      key: "companyId",
      dataIndex: "CompanyId",
      render: (companyId) => (
        <Link to={toCompany(companyId)}>{companyId.slice(0, 8)}</Link>
      ),
    },
    {title: "Name", key: "name", dataIndex: "name", filtered: true},
    {title: "Customer ID", key: "customerId", dataIndex: "customerId", filtered: true},
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => t(status ?? "--"),
      filtered: false,
    },
    {
      title: "Subscription",
      key: "subscription",
      dataIndex: "SubscribeUnit",
      render: (SubscribeUnit) => t(SubscribeUnit?.SubscribeProduct?.name ?? "--"),
      filtered: true,
    },
    {
      title: "Regions",
      key: "regions",
      dataIndex: "SearchPreference",
      render: (search) =>
        search?.regionsCount === 23
          ? t("FIRST_SETUP_REGION_00")
          : search?.regionsCount ?? 0,
      filtered: true,
    },
    {
      title: "Seats",
      key: "seats",
      dataIndex: "usersCount",
      filtered: true,
    },
    ...defaultTableColumns,
  ];

  return (
    <Table
      rowKey="id"
      loading={loading}
      dataSource={dataSource}
      pagination={pagination}
      style={style}
      columns={columns}
    />
  );
};
