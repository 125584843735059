import React from "react";
import {Table} from "antd";
import {Link} from "react-router-dom";
import {toCompany, toOrganization} from "../../../constants/routes.const";
import {useTranslation} from "react-i18next";
import {arrayToCommaAnd} from "helpers/helper";

export const OrganizationsTable = (props) => {
  const {t} = useTranslation();

  const organizationColumns = [
    {
      title: "ID",
      key: "id",
      dataIndex: "id",
      render: (id) => <Link to={toOrganization(id)}>{id.slice(0, 8)}</Link>,
    },
    {
      title: "Company",
      key: "companyId",
      dataIndex: "CompanyId",
      render: (companyId) => (
        <Link to={toCompany(companyId)}>{companyId.slice(0, 8)}</Link>
      ),
    },
    {title: "Name", key: "name", dataIndex: "name", filtered: true},
    {title: "Customer ID", key: "customerId", dataIndex: "customerId", filtered: true},
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => t(status ?? "--"),
      filtered: false,
    },
    {
      title: "Subscription",
      key: "subscription",
      dataIndex: "SubscribeUnit",
      render: (SubscribeUnit) =>
        arrayToCommaAnd(
          SubscribeUnit?.SubscribeProducts?.map((item) => t(item.name)),
          ", "
        ),
      filtered: true,
    },
    {
      title: "Regions",
      key: "regions",
      dataIndex: "SearchPreference",
      render: (search) =>
        search?.regionsCount === 23
          ? t("FIRST_SETUP_REGION_00")
          : search?.regionsCount ?? 0,
      filtered: true,
    },
    {
      title: "Seats",
      key: "seats",
      dataIndex: "usersCount",
      filtered: true,
    },
    {
      title: "Created (CET time)",
      key: "created",
      dataIndex: "createdAt",
      render: (date) => new Date(date).toLocaleString("sv"),
    },
  ];

  return (
    <Table
      rowKey="id"
      {...props}
      columns={organizationColumns}
      className="organizations-table"
    />
  );
};
