import React from "react";
import AdminFormsLayout from "../../../components/AdminFormsLayout/AdminFormsLayout";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {ADMIN_LOGIN} from "../../../constants/routes.const";

const AdminResetPassDone = () => {
  const {t} = useTranslation();
  return (
    <AdminFormsLayout>
      <p>{t("RESET_PASS_DONE_1")}</p>
      <p>{t("RESET_PASS_DONE_2")}</p>
      <div>
        <Link to={ADMIN_LOGIN}>{t("GO_TO_LOGIN")}</Link>
      </div>
    </AdminFormsLayout>
  );
};

export default AdminResetPassDone;
