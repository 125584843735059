import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {schema, initialValues} from "./formAttrs";
import {Form, Formik} from "formik";
import FormikField from "components/FormikField";
import {Button} from "components/buttons/Button";
import {inject, observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
import {showSuccess} from "helpers/notifications.helper";
import {ADMIN_LOGIN} from "../../../../../constants/routes.const";

const AdminRegisterInfoForm = inject("store")(
  observer(({store: {admin}}) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = (values) => {
      setLoading(true);
      admin
        .sendAdminRegistrationInfo(values)
        .then(() => {
          showSuccess("Successfully submitted!");
          navigate(ADMIN_LOGIN);
        })
        .finally(() => setLoading(false));
    };

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({dirty, isValid}) => (
          <Form>
            <FormikField
              label={t("LOGIN_PLACEHOLDER_FIRST_NAME")}
              name="firstName"
              errorName="firstName"
              placeholder={t("LOGIN_PLACEHOLDER_FIRST_NAME")}
              className="admin_authentication__form __field"
            />
            <FormikField
              label={t("LOGIN_PLACEHOLDER_LAST_NAME")}
              name="lastName"
              errorName="lastName"
              placeholder={t("LOGIN_PLACEHOLDER_LAST_NAME")}
              className="admin_authentication__form __field"
            />
            <FormikField
              type="email"
              label={t("E_MAIL")}
              name="email"
              errorName="email"
              placeholder={t("LOGIN_PLACEHOLDER_EMAIL")}
              className="admin_authentication__form __field"
            />
            <FormikField
              type="password"
              label={t("LOGIN_PASSWORD")}
              name="password"
              errorName="password"
              placeholder={t("LOGIN_PLACEHOLDER_PASSWORD")}
              className="admin_authentication__form __field"
            />
            <div className="admin_authentication__form __actions">
              <Button
                type="submit"
                loading={loading}
                name={t("CONFIRM")}
                disabled={!dirty || !isValid}
              />
            </div>
          </Form>
        )}
      </Formik>
    );
  })
);

export default AdminRegisterInfoForm;
