import axios from "axios";
import {showError} from "helpers/notifications.helper";
import {getStorage, removeStorage} from "helpers/storage";
import {ADMIN_LOGIN} from "constants/routes.const";

const axiosInstance = axios.create({baseURL: process.env.REACT_APP_API_URL});

axiosInstance.interceptors.request.use((config) => {
  let Authorization = `jwt ${getStorage("admin_token")}`;
  const apikey = process.env.REACT_APP_API_KEY;
  config.headers = {Authorization, apikey};
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response && error.message) {
      showError(error.message);
      return Promise.reject(error);
    }

    const {status, data} = error.response;

    if (status === 401) {
      const path = window.location.pathname;
      removeStorage("admin_token");
      if (path !== ADMIN_LOGIN) window.location.pathname = ADMIN_LOGIN;
    }

    if (data?.error && typeof data.error === "string") showError(data.error);

    return Promise.reject(error);
  }
);

export default axiosInstance;
