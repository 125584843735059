import React, {useState, useCallback} from "react";
import AdminLayout from "../_layout/AdminLayout";
import {Pagination, Form, Input, Button} from "antd";
import {useCompanies} from "../../api/companies";
import {CompaniesTable} from "./component/CompaniesTable";

const CompaniesPage = () => {
  const pageSize = 20;
  const [page, setPage] = useState(1);
  const [searchForm] = Form.useForm();
  const [{name, number}, setFilter] = useState({});
  const [, companiesData, loading] = useCompanies(name, number, page, pageSize);

  const onReset = useCallback(() => {
    searchForm.resetFields();
    setFilter({name: "", number: ""});
  }, [searchForm]);

  const onSearch = useCallback(() => {
    setFilter(searchForm.getFieldsValue());
  }, [setFilter, searchForm]);

  return (
    <AdminLayout>
      <div className="content-box">
        <h1>Companies ({companiesData?.totalItems ?? "0"} items)</h1>

        <div className="df-row-center mb-3">
          <h3 className="subtitle mr-6">Search -&gt;</h3>
          <Form form={searchForm} layout="inline" onFinish={onSearch}>
            <Form.Item label="Name" name="name">
              <Input />
            </Form.Item>
            <Form.Item label="Company number" name="number">
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Search
              </Button>
            </Form.Item>
            <Form.Item>
              <Button htmlType="button" onClick={onReset}>
                Reset
              </Button>
            </Form.Item>
          </Form>
        </div>
        <CompaniesTable
          loading={loading}
          dataSource={companiesData?.companies}
          pagination={false}
        />
        <Pagination
          total={companiesData?.totalItems}
          current={page}
          pageSize={20}
          showSizeChanger={false}
          onChange={setPage}
          style={{marginTop: 15}}
        />
      </div>
    </AdminLayout>
  );
};

export default CompaniesPage;
