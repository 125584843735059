import React from "react";
import AdminHeader from "./AdminHeader";
import AdminSidebar from "./AdminSidebar";
import AdminFooter from "./AdminFooter";
import {inject, observer} from "mobx-react";

const AdminLayout = inject("store")(
  observer(({store: {admin}, children}) => {
    return (
      <div className="admin__wrapper">
        <AdminSidebar />
        <div className="admin_body__wrapper">
          <AdminHeader adminUserInfo={admin.adminUserInfo} />
          <div className="admin_content__wrapper">{children}</div>
        </div>
        <AdminFooter />
      </div>
    );
  })
);

export default AdminLayout;
