import React from "react";
// import React, {useCallback} from "react";
import {Link} from "react-router-dom";
import {
  ADMIN,
  ADMIN_TRANSLATIONS,
  APPLYING_USER,
  ORGSNOBANKID,
  COMPANIES,
  ORGANIZATIONS,
  COMMON_SETTINGS,
  USERS,
  SUBSCRIPTION_ORDERS,
  ADVERTISEMENTS,
  CREATE_ANY_COMPANY,
  OPEN_RFPS,
} from "../../constants/routes.const";
// import // useUpdateCheckbizCompanies,
// useUpdateByggforetagenCompanies,
// useUpdateKollektivavtalskollenCompanies,
// useUpdateBygginstsektornCompanies,
// "../../api/companies";
// import {showSuccess} from "helpers/notifications.helper";
// import {ConfirmModal} from "components/ConfirmModal";
// import {useTranslation} from "react-i18next";

const AdminSidebar = () => {
  // const {t} = useTranslation();
  // const [, , , updateCheckbizRequest] = useUpdateCheckbizCompanies();
  // const [, , , updateByggforetagenRequest] = useUpdateByggforetagenCompanies();
  // const [, , , updateBygginstsektorn] = useUpdateBygginstsektornCompanies();
  // const [, , , updateKollektivavtalskollenRequest] =
  // useUpdateKollektivavtalskollenCompanies();

  // const clickUpdateCheckbiz = () =>
  //   ConfirmModal({
  //     title: t("WANT_UPDATE"),
  //     text: t("WANT_UPDATE_CHECKBIZ"),
  //     type: "warning",
  //     onOk: onUpdateCheckbiz,
  //     onCancel: () => {},
  //   });

  // const clickUpdateByggforetagen = () =>
  //   ConfirmModal({
  //     title: t("WANT_UPDATE"),
  //     text: t("WANT_UPDATE_BYGGFORETAGEN"),
  //     type: "warning",
  //     onOk: onUpdateByggforetagen,
  //     onCancel: () => {},
  //   });

  // const clickUpdateBygginstsektorn = () =>
  //   ConfirmModal({
  //     title: t("WANT_UPDATE"),
  //     text: t("WANT_UPDATE_BYGGFORETAGEN"),
  //     type: "warning",
  //     onOk: onUpdateBygginstsektorn,
  //     onCancel: () => {},
  //   });

  // const clickUpdateKollektivavtalskollen = () =>
  //   ConfirmModal({
  //     title: t("WANT_UPDATE"),
  //     text: t("WANT_UPDATE_KOLLEKTIVAVTALSKOLLEN"),
  //     type: "warning",
  //     onOk: onUpdateKollektivavtalskollen,
  //     onCancel: () => {},
  //   });

  // const onUpdateCheckbiz = useCallback(() => {
  //   updateCheckbizRequest().then(() => showSuccess());
  // }, [updateCheckbizRequest]);

  // const onUpdateByggforetagen = useCallback(() => {
  //   updateByggforetagenRequest().then(() => showSuccess());
  // }, [updateByggforetagenRequest]);

  // const onUpdateBygginstsektorn = useCallback(() => {
  //   updateBygginstsektornRequest().then(() => showSuccess());
  // }, [updateBygginstsektornRequest]);

  // const onUpdateKollektivavtalskollen = useCallback(() => {
  //   updateKollektivavtalskollenRequest().then(() => showSuccess());
  // }, [updateKollektivavtalskollenRequest]);

  return (
    <div className="admin_sidebar__wrapper">
      <div className="admin_sidebar__brand">
        <Link to={ADMIN}>HANDYDAY</Link>
        <div style={{color: "#FFF"}}>
          {process.env.REACT_APP_USE_SMS_API === "yes"
            ? "PRODUCTION"
            : process.env.NODE_ENV === "development"
            ? "DEVELOPMENT"
            : "STAGING"}
        </div>
      </div>
      <div className="admin_sidebar__list">
        <Link to={ADMIN}>Dashboard</Link>
        <Link to={COMMON_SETTINGS}>Settings</Link>
        <Link to={ADMIN_TRANSLATIONS}>Translations</Link>
        <Link to={ORGANIZATIONS}>Organizations</Link>
        <Link to={COMPANIES}>Companies</Link>
        <Link to={SUBSCRIPTION_ORDERS}>Subscription orders</Link>
        <Link to={ADVERTISEMENTS}>Advertisements</Link>
        <Link to={USERS}>Users</Link>
        <Link to={OPEN_RFPS}>Open RFPs</Link>
        <Link to={APPLYING_USER}>Applying Users pending approval</Link>
        <Link to={ORGSNOBANKID}>Organizations without BankId authorization</Link>
      </div>

      {/* <h3 className="admin_sidebar__sub-title">Get external data</h3> */}
      <div className="admin_sidebar__list">
        {/* <Link to="" onClick={clickUpdateByggforetagen}>
          Byggforetagen
        </Link>
        <Link to="" onClick={clickUpdateBygginstsektorn}>
          Bygginstsektorn
        </Link>
        <Link to="" onClick={clickUpdateKollektivavtalskollen}>
          Kollektivavtalskollen
        </Link> */}
        <h3 className="admin_sidebar__sub-title">Create companies</h3>
        <Link to={CREATE_ANY_COMPANY}>Create any company</Link>
      </div>
    </div>
  );
};

export default AdminSidebar;
