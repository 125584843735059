import React, {memo} from "react";

export const Input = memo(
  ({
    name,
    label,
    addClass,
    onChange,
    onsubmit,
    value,
    defaultValue,
    type,
    focus,
    onKeyUp,
    maxLength,
    placeholder,
    pattern,
    autoFocus,
    disabled,
    readOnly,
  }) => {
    return (
      <div className="form">
        {label ? <label className="label">{label}</label> : null}
        <input
          className={`input ${addClass ? addClass : ""}`}
          onChange={onChange}
          onSubmit={onsubmit}
          onKeyUp={onKeyUp}
          value={value}
          defaultValue={defaultValue}
          type={type}
          ref={focus}
          autoFocus={autoFocus}
          name={name}
          placeholder={placeholder}
          maxLength={maxLength}
          pattern={pattern}
          disabled={disabled}
          readOnly={readOnly}
        />
      </div>
    );
  }
);
