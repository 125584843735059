import React, {useState} from "react";
import {Table} from "antd";
import {Checkbox, InputNumber} from "antd";
import {useTranslation} from "react-i18next";
// local
import {Preloader} from "components/Preloader";
import {CustomTooltip} from "components/CustomTooltip";

export const PermissionsTable = ({
  subscriptionColumns,
  idKey,
  onChange,
  checkLoading,
  needScroll,
  ...props
}) => {
  const {t} = useTranslation();
  const [nameView, setNameView] = useState(true);

  const columns = [
    {
      title: (
        <span>
          {"Permissions ( "}
          <Checkbox checked={nameView} onChange={(e) => setNameView(e.target.checked)} />
          {" change view )"}
        </span>
      ),
      key: nameView ? "name" : "id",
      dataIndex: nameView ? "name" : "id",
      width: 300,
      className: "permissions-column",
      render: (value, permission) => (
        <CustomTooltip
          text={`ID: ${permission[nameView ? "id" : "name"]}\n\n${t("DESCRIPTION")}: ${
            permission.description
          }`}
        >
          {value}
        </CustomTooltip>
      ),
      fixed: "left",
    },
  ];

  const SubscriptionColumn = ({id, permissionId, checked, disabled, quantity}) => {
    const [inputQuantity, setInputQuantity] = useState(quantity);

    return checkLoading[0] === id && checkLoading[1] === permissionId ? (
      <Preloader size="small" addClass="mt-0" />
    ) : (
      <div className="df-row-center">
        <Checkbox
          checked={checked}
          disabled={disabled}
          onChange={(e) => onChange(id, permissionId, e.target.checked, inputQuantity)}
        />
        {checked && !disabled && (
          <span className="quantity-block pl-3 fz-12">
            qty:{" "}
            <InputNumber
              value={inputQuantity}
              size="small"
              onChange={(value) => {
                onChange(id, permissionId, true, value);
                setInputQuantity(value);
              }}
              style={{width: "55%"}}
            />
          </span>
        )}
        {checked && !disabled && !!inputQuantity && (
          <span className="quantity-hover pl-3 fz-12">quantity: {inputQuantity}</span>
        )}
      </div>
    );
  };

  return (
    <Table
      rowKey="name"
      sticky
      size="small"
      scroll={
        needScroll
          ? {
              x: 1600,
            }
          : undefined
      }
      {...props}
      columns={[
        ...columns,
        ...(subscriptionColumns?.map((sub) => ({
          ...sub,
          render: (item, record) => (
            <SubscriptionColumn
              checked={item?.checked}
              quantity={item?.quantity}
              disabled={item.disabled}
              permissionId={record.id}
              id={item?.[idKey]}
            />
          ),
        })) ?? []),
      ]}
      className="permissions"
    />
  );
};
