import {showError} from "./notifications.helper";
import i18next from "i18next";

export const catchFieldErrors = (error, setFieldError) => {
  /*** Show field errors comes from response message ***/
  const fieldErrors = error.response.data?.error;
  const language = i18next.language;
  if (typeof fieldErrors === "object" && Object.keys(fieldErrors).length) {
    for (const [key, value] of Object.entries(fieldErrors)) {
      if (typeof value === "string") {
        setFieldError(key, value);
      } else if (typeof value === "object" && Object.keys(value).length) {
        setFieldError(key, value[language]);
      }
    }
  } else {
    showError(error.message);
  }
};
