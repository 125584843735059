import {showWarning} from "./notifications.helper";

export const beforeUpload = (file) => {
  const isPdfOrPng = file.type === "image/png" || file.type === "application/pdf";
  if (!isPdfOrPng) {
    showWarning("You can only upload PDF or PNG file!");
  }

  const isLt5M = file.size / 1024 / 1024 < 5;
  if (!isLt5M) {
    showWarning("Image must smaller than 5MB!");
  }
  return isPdfOrPng && isLt5M;
};

export const beforeUploadPNGJPEGSVG = (file) => {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/jpg" ||
    file.type === "image/svg+xml";
  if (!isJpgOrPng) showWarning("You can only upload a JPG, JPEG, PNG or SVG file");
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M) showWarning("Image size must be smaller than 5MB");
  return isJpgOrPng && isLt2M;
};
