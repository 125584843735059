import * as yup from "yup";
import {emailRequired, stringRequired} from "../../../../../yup/defaultValudations";

export const schema = yup.object().shape({
  firstName: stringRequired(),
  lastName: stringRequired(),
  email: emailRequired(),
  password: stringRequired(),
});

export const initialValues = {firstName: "", lastName: "", email: "", password: ""};
