import React, {useState} from "react";
import {Button, Input, Popconfirm} from "antd";

export const MonthlyCredits = ({
  creditsUpdateLimit,
  creditsUpdateUsed,
  submitCredits,
  resetUsedCredits,
}) => {
  const [newMonthlyCredits, setNewMonthlyCredits] = useState(null);

  return (
    <div>
      <div className="df-row-center">
        <span className="mr-2">{`${creditsUpdateLimit} total`}</span>
        <Input
          placeholder="Set new limit"
          value={newMonthlyCredits}
          onKeyDown={(e) => {
            if (e.key !== "Backspace" && !/[0-9]/.test(e.key)) {
              e.preventDefault(); // Prevent non-digit characters
            }
          }}
          onChange={(e) => setNewMonthlyCredits(Number(e.target.value))}
          style={{width: "10%"}}
        />

        <Popconfirm
          title="Are you sure you want to set a new monthly credit limit?"
          onConfirm={() => {
            submitCredits({creditsUpdateLimit: newMonthlyCredits});
            setNewMonthlyCredits(null);
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button type="primary" className="ml-2">
            Set new limit
          </Button>
        </Popconfirm>

        <Popconfirm
          title="Are you sure you want to reset used credits to 0?"
          onConfirm={() => {
            resetUsedCredits({creditsUpdateUsed: 0});
          }}
          okText="Yes"
          cancelText="No"
        >
          <span className="ml-2">{`${creditsUpdateUsed} used`}</span>
          <Button type="secondary" className="ml-2">
            Reset Used Credits
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
};
