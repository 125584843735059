import React from "react";
import {Table} from "antd";
import {Link} from "react-router-dom";
import {toOrganization} from "../../../constants/routes.const";
import {useTranslation} from "react-i18next";

export const SubscriptionsOrdersTable = (props) => {
  const {t} = useTranslation();

  const organizationColumns = [
    {
      title: "Organization",
      key: "organization",
      dataIndex: "Organization",
      render: (Organization) => (
        <Link to={toOrganization(Organization.id)}>{Organization.name}</Link>
      ),
      className: "organization-column",
    },
    {
      title: "Order number",
      key: "orderNumber",
      dataIndex: "SubscribeOrder",
      render: (SubscribeOrder) => SubscribeOrder?.orderNumber,
      filtered: true,
    },
    {
      title: "Customer ID",
      key: "customerId",
      dataIndex: "Organization",
      render: (Organization) => Organization.customerId,
      filtered: true,
    },
    {
      title: "Current subscriptions (Interval)",
      key: "SubscribeProducts",
      dataIndex: "SubscribeProducts",
      render: (SubscribeProducts) =>
        SubscribeProducts?.map(
          (item) =>
            `${t(item.name)} (${t(
              `ADMIN_ORGANIZATIONS_SUBSCRIPTION_${item.SubscribeUnitProduct?.pricePeriod}`
            )})`
        ).join(", "),
      className: "current-subscriptions-column",
      filtered: true,
    },
    {
      title: "Total price",
      key: "price",
      dataIndex: "price",
      render: (price) => `${price.replace(".00", "")} kr`,
      filtered: true,
    },
    {
      title: "Subscribe order",
      key: "subscribeOrder",
      dataIndex: "SubscribeOrder",
      render: (SubscribeOrder) =>
        SubscribeOrder?.SubscribeProduct
          ? `${t(SubscribeOrder.SubscribeProduct.name)} - 
          ${SubscribeOrder.price.replace(".00", "")} kr`
          : "",
      filtered: false,
    },
    {
      title: "Possible codes",
      key: "possibleCodes",
      dataIndex: "SubscribeOrder",
      render: (SubscribeOrder) => SubscribeOrder?.discountCode ?? "",
      filtered: false,
      className: "possible-codes-column",
    },
    {
      title: "Client user",
      key: "clientUser",
      dataIndex: "User",
      render: (User) => `${User?.firstName ?? ""} ${User?.lastName ?? ""}`,
    },
    {
      title: "Admin user",
      key: "adminUser",
      dataIndex: "AdminUser",
      render: (AdminUser) => `${AdminUser?.firstName ?? ""} ${AdminUser?.lastName ?? ""}`,
    },
    {
      title: "Created (CET time)",
      key: "created",
      dataIndex: "createdAt",
      render: (date) => new Date(date).toLocaleString("sv"),
    },
  ];

  return (
    <Table
      rowKey="id"
      {...props}
      columns={organizationColumns}
      className="subscription-orders-table"
    />
  );
};
