import React, {forwardRef} from "react";
import {useTranslation} from "react-i18next";
import cn from "classnames";
import {Preloader} from "../Preloader";

export const ButtonTransparent = forwardRef((props, ref) => {
  const {
    name,
    type,
    onClick,
    img,
    icon,
    imgRight,
    iconRight,
    addClass,
    disabled,
    loading,
    style,
    nonTransparent,
    small,
    large,
  } = props;

  const {t} = useTranslation();
  const styles = {
    background: disabled && !loading && "#D8D8D8",
    color: disabled && "#A9A9A9",
    fontSize: small && "12px",
    borderColor: disabled && "#A9A9A9",
    ...style,
  };

  return (
    <button
      className={cn(
        "btn-custom_main btn-transparent",
        {[addClass]: !!addClass},
        {"__full-bg": nonTransparent},
        {"__center-loader": loading},
        {__small: small},
        {__large: large},
        {"__image-right": imgRight || iconRight}
      )}
      onClick={onClick}
      style={styles}
      type={type || "button"}
      disabled={disabled || loading}
      ref={ref}
    >
      {loading ? (
        <Preloader size="default" addClass="mt-1" />
      ) : (
        <>
          {(img && <img src={img} alt="" />) || icon}
          <span>{t(name)}</span>
          {(imgRight && <img src={imgRight} alt="" />) || iconRight}
        </>
      )}
    </button>
  );
});
