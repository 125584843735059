import React from "react";
import {Table} from "antd";
import {Link} from "react-router-dom";
import {toCompany} from "../../../constants/routes.const";

const companyColumns = [
  {
    title: "ID",
    key: "id",
    dataIndex: "id",
    render: (id) => <Link to={toCompany(id)}>{id.slice(0, 8)}</Link>,
  },
  {title: "Name", key: "name", dataIndex: "companyName", filtered: true},
  {title: "Number", key: "number", dataIndex: "companyNumber", filtered: true},
  {
    title: "Created (CET time)",
    key: "created",
    dataIndex: "createdAt",
    render: (date) => new Date(date).toLocaleString("sv"),
  },
];

export const CompaniesTable = ({...props}) => {
  return <Table rowKey="id" {...props} columns={companyColumns} />;
};
