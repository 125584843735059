import React from "react";
import {inject, observer} from "mobx-react";
import Pagination from "react-js-pagination";

const TranslationsPagination = inject("store")(
  observer(({store: {admin}}) => {
    const {
      translationData: {totalItems, currentPage},
    } = admin;

    const handlePageChange = (pageNumber) => {
      if (pageNumber !== currentPage) {
        admin.getTranslations(pageNumber);
      }
    };

    return (
      <div className="admin-translation_item__wrapper __footer">
        <div className="pagination-static_wrapper">
          <Pagination
            activePage={parseInt(currentPage)}
            totalItemsCount={totalItems}
            itemsCountPerPage={20}
            pageRangeDisplayed={10}
            onChange={handlePageChange.bind(this)}
            itemClass="page-item"
            linkClass="page-link"
          />
        </div>
      </div>
    );
  })
);

export default TranslationsPagination;
