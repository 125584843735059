import React from "react";
import SubPageHeader from "components/subPageSegments/SubPageHeader";
import SubPageFooter from "components/subPageSegments/SubPageFooter";

const AdminFormsLayout = ({children, formTitle, addClass}) => {
  return (
    <div className={`admin_authentication__wrapper ${addClass}`}>
      <SubPageHeader />

      <div className="admin_authentication__content __wrapper">
        <h2 className="admin_authentication__title">{formTitle}</h2>
        <div className="admin_authentication__form __wrapper">{children}</div>
      </div>

      <SubPageFooter />
    </div>
  );
};

export default AdminFormsLayout;
