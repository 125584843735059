import React from "react";
import {inject, observer} from "mobx-react";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import downloadPdf from "assets/images/svg/download-pdf.svg";

const DownloadTranslationsBase = inject("store")(
  observer(({store: {admin}}) => {
    const downloadTranslations = () => {
      admin.downloadTranslations().then(({data}) => {
        const blob = new Blob([data], {type: "text/csv"});
        const csvURL = window.URL.createObjectURL(blob);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", "translation.csv");
        tempLink.click();
      });
    };
    return (
      <ButtonTransparent
        name="Download"
        type="button"
        img={downloadPdf}
        onClick={downloadTranslations}
      />
    );
  })
);

export default DownloadTranslationsBase;
