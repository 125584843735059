import AdminStore from "./admin.store";

class RootStore {
  constructor() {
    this.admin = AdminStore;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new RootStore();
