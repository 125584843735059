import React from "react";

import AdminRegisterPhonePage from "../components/AdminRegisterPhonePage";
import AdminRegisterCodePage from "../components/AdminRegisterCodePage";
import AdminRegisterInfoPage from "../components/AdminRegisterInfoPage";

export const steps = [
  {
    content: <AdminRegisterPhonePage />,
  },
  {
    content: <AdminRegisterCodePage />,
  },
  {
    content: <AdminRegisterInfoPage />,
  },
];
