import {useApi} from "./useApi";

export const useAdminInfo = () => useApi("admin/admin-user-info");

export const useAdminUserLogs = () => useApi("admin/userslogs");

export const useAdminUniqueUsers = () => useApi("admin/unique-users-today");

export const useAdminActiveRFPs = () => useApi("admin/active-rfps-today");

export const useUsersOnline = () => useApi("admin/active-socket-connections-online");
