import React from "react";
import AdminLayout from "../_layout/AdminLayout";
import {Table} from "antd";
import {Link} from "react-router-dom";
import {toCompany} from "../../constants/routes.const";
import {useAdminActiveRFPs} from "../../api/admin-info";

const OpenRFPs = () => {
  const [, openRFPs, loading] = useAdminActiveRFPs();

  const columns = [
    {
      title: "Company number",
      dataIndex: "id",
      render: (id, o) => {
        return <Link to={toCompany(id)}>{o.companyNumber}</Link>;
      },
    },
    {
      title: "Formal company name",
      dataIndex: "Formal company name",
    },
    {
      title: "RFP name",
      dataIndex: "RFP name",
    },
    {
      title: "RFP close date",
      dataIndex: "RFP close date",
    },
    {
      title: "Organization invited to RFP",
      dataIndex: "Organization invited to RFP",
    },
    {
      title: "Interested",
      dataIndex: "interested",
    },
  ];
  return (
    <AdminLayout>
      <div className="content-box">
        <h1>Open RFPs ({openRFPs?.resultCount ?? "0"} items)</h1>
      </div>
      <Table
        loading={loading}
        dataSource={openRFPs?.results.map((r, i) => {
          r.key = i;
          return r;
        })}
        columns={columns}
        pagination={false}
      />
    </AdminLayout>
  );
};
export default OpenRFPs;
