import React, {useCallback, useEffect, useState} from "react";
import {Select} from "antd";
import {useTranslation} from "react-i18next";
//local
import {LANGUAGE} from "../constants/language.constants";
import enIcon from "assets/images/svg/enIcon.svg";
import svIcon from "assets/images/svg/svIcon.svg";
import fiIcon from "assets/images/svg/fiIcon.svg";
import {getStorage, setStorage} from "helpers/storage";

export const Language = () => {
  const [language, setLanguage] = useState();
  const {Option} = Select;
  const {i18n} = useTranslation();
  const {t} = useTranslation();

  const setLanguageGlobal = useCallback(
    (lng) => {
      i18n.changeLanguage(lng).then(() => {
        setLanguage(lng);
      });
    },
    [i18n]
  );

  const handleChange = (lng) => {
    setLanguageGlobal(lng);
    setStorage(LANGUAGE, lng);
  };

  const selectLanguage = (language) => {
    switch (language) {
      case "en":
        return <img src={enIcon} alt="wn" />;
      case "sv":
        return <img src={svIcon} alt="sv" />;
      case "fi":
        return <img src={fiIcon} alt="fi" />;
      default:
        return null; // Optional default case
    }
  };

  useEffect(() => {
    const lng = getStorage(LANGUAGE);
    setLanguageGlobal(lng);
  }, [language, setLanguageGlobal]);

  return (
    <div className="language">
      {selectLanguage(language)}
      <Select
        className="language_select"
        value={language || "Swedish"}
        onChange={handleChange}
      >
        <Option value="sv">{t("LANGUAGE_SWEDISH")}</Option>
        <Option value="en">{t("LANGUAGE_ENGLISH")}</Option>
        <Option value="fi">{t("LANGUAGE_FINNISH")}</Option>
      </Select>
    </div>
  );
};
