import React from "react";
import {useTranslation} from "react-i18next";
import {Preloader} from "../Preloader";
import {inject, observer} from "mobx-react";
import cn from "classnames";

export const Button = inject("store")(
  observer(
    ({
      store: {admin},
      name,
      disabled,
      loading,
      onClick,
      style,
      addClass,
      type,
      img,
      icon,
      imgRight,
      iconRight,
    }) => {
      const {btnLoading} = admin;
      const {t} = useTranslation();
      const styles = {
        background: disabled && !btnLoading && !loading && "#D8D8D8",
        color: disabled && "#A9A9A9",
        ...style,
      };

      return (
        <button
          disabled={btnLoading || disabled || loading}
          style={styles}
          className={cn("btn", {[addClass]: !!addClass})}
          onClick={onClick}
          type={type || "button"}
        >
          {btnLoading || loading ? (
            <Preloader addClass="btn-loader" size="default" />
          ) : (
            <>
              {(img && <img src={img} alt="" />) || icon}
              {t(name)}
              {(imgRight && <img src={imgRight} alt="" />) || iconRight}
            </>
          )}
        </button>
      );
    }
  )
);
