import {useApi} from "./useApi";

// ****** WORKING WITH COMPANIES ******

export const useCompanies = (
  companyName = "",
  companyNumber = "",
  page = 1,
  size = 20
) => {
  return useApi("admin/companies/search", {
    method: "post",
    data: {companyName, companyNumber, page, size},
  });
};

export const useCompany = (id) => useApi(`admin/companies/${id}`);

export const useSearchCompanies = ({name = "", number = ""}, limit = 20) => {
  return useApi("admin/companies/search", {
    method: "post",
    data: {companyName: name, companyNumber: number, limit},
  });
};

export const useDeleteCompany = (id) =>
  useApi(`admin/companies/${id}`, {method: "delete", autoRequest: false});

export const useUpdateCreditSafeBasic = (id) =>
  useApi(`admin/companies/${id}/updateCreditSafeBasic`, {
    method: "post",
    autoRequest: false,
  });

export const useUpdateCreditSafeCredit = (id) =>
  useApi(`admin/companies/${id}/updateCreditSafeCredit`, {
    method: "post",
    autoRequest: false,
  });

export const useUpdateByggforetagenCompanies = () =>
  useApi(`admin/companies/updateByggforetagen`, {
    method: "post",
    autoRequest: false,
  });

export const useUpdateBygginstsektornCompany = (id) =>
  useApi(`admin/companies/${id}/updateBygginstsektorn`, {
    method: "post",
    autoRequest: false,
  });

export const useUpdateBygginstsektornCompanies = () =>
  useApi(`admin/companies/updateBygginstsektorn`, {
    method: "post",
    autoRequest: false,
  });

export const useUpdateByggforetagenCompany = (id) =>
  useApi(`admin/companies/${id}/updateByggforetagen`, {
    method: "post",
    autoRequest: false,
  });

export const useUpdateKollektivavtalskollenCompanies = () =>
  useApi(`admin/companies/updateKollektivavtalskollen`, {
    method: "post",
    autoRequest: false,
  });

export const useUpdateKollektivavtalskollenCompany = (id) =>
  useApi(`admin/companies/${id}/updateKollektivavtalskollen`, {
    method: "post",
    autoRequest: false,
  });

export const useUpdateCheckbizCompanies = () =>
  useApi(`admin/companies/updateCheckbiz`, {method: "post", autoRequest: false});

export const useUpdateAllaBolag = (id) =>
  useApi(`admin/companies/${id}/updateAllaBolag`, {
    method: "post",
    autoRequest: false,
  });
