import {showInfo} from "./notifications.helper";

const copyToClipboard = (text) => {
  const textField = document.createElement("textarea");
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
  showInfo("Text copied", 2);
};

export {copyToClipboard};
