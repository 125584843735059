export const defaultTableColumns = [
  {
    title: "Created (CET time)",
    key: "created",
    dataIndex: "createdAt",
    render: (date) => new Date(date).toLocaleString("sv"),
  },
  {
    title: "Updated (CET time)",
    key: "updated",
    dataIndex: "updatedAt",
    render: (date) => new Date(date).toLocaleString("sv"),
  },
];
