import {REQUESTS, SAVE_SEARCH, OUR_PROFILE} from "constants/routes.const";
import card01Img from "assets/images/svg/onboarding/onboarding-search.svg";
import card02Img from "assets/images/svg/onboarding/onboarding-procure.svg";
import card03Img from "assets/images/svg/onboarding/onboarding-profile.svg";

export const onboardingCards = [
  {
    name: "filterSetup",
    title: "ONBOARDING_MODAL_CARD01_TITLE",
    image: card01Img,
    description: "ONBOARDING_MODAL_CARD01_DESCRIPTION",
    linkTo: SAVE_SEARCH,
    passed: false,
  },
  {
    name: "procuring",
    title: "ONBOARDING_MODAL_CARD02_TITLE",
    image: card02Img,
    description: "ONBOARDING_MODAL_CARD02_DESCRIPTION",
    linkTo: REQUESTS,
    passed: false,
  },
  {
    name: "manageProfile",
    title: "ONBOARDING_MODAL_CARD03_TITLE",
    image: card03Img,
    description: "ONBOARDING_MODAL_CARD03_DESCRIPTION",
    linkTo: OUR_PROFILE,
    passed: false,
  },
];

export const subscriptionPriceTypeDescription = {
  PRICE_PER_MONTH: "PRICE_PER_MONTH_DESCRIPTION",
  PRICE_PER_USER_MONTH: "PRICE_PER_USER_MONTH_DESCRIPTION",
};

export const subscriptionPricePeriod = {
  PER_SIX_MONTH: "PRICE_PERIOD_PER_6_MONTH",
  PER_YEAR: "PRICE_PERIOD_PER_12_MONTH",
};

export const subscriptionPriceKey = {
  ANNUAL: "priceAnnual",
  SEMIANNUAL: "priceSemiannual",
};

export const subscriptionPriceKeys = {
  [subscriptionPricePeriod.PER_SIX_MONTH]: subscriptionPriceKey.SEMIANNUAL,
  [subscriptionPricePeriod.PER_YEAR]: subscriptionPriceKey.ANNUAL,
};

export const subscriptionPricePeriods = [
  {
    priceKey: subscriptionPriceKey.ANNUAL,
    periodKey: subscriptionPricePeriod.PER_YEAR,
  },
  {
    priceKey: subscriptionPriceKey.SEMIANNUAL,
    periodKey: subscriptionPricePeriod.PER_SIX_MONTH,
  },
];
