import React from "react";
import {Table} from "antd";

const advertisementColumns = [
  {title: "ID", key: "id", dataIndex: "id", filtered: false, className: "id-column"},
  {
    title: "Name",
    key: "name",
    dataIndex: "name",
    filtered: false,
    className: "name-column",
  },
  {
    title: "Link",
    key: "link",
    dataIndex: "link",
    filtered: false,
    className: "link-column",
  },
  {
    title: "Link to source",
    key: "AdvertisementImageFile",
    dataIndex: "AdvertisementImageFile",
    render: (imageFile, row) => (
      <a
        href={row.type === "image" ? imageFile?.File?.url : row.linkDesktop}
        target="_blank"
        rel="noreferrer"
      >
        {row.type === "image" ? imageFile?.File?.url : row.linkDesktop}
      </a>
    ),
    filtered: false,
  },
];

export const AdvertisementsTable = ({onClickRow, ...props}) => {
  return (
    <Table
      rowKey="id"
      {...props}
      columns={advertisementColumns}
      rowClassName="cpointer"
      onRow={(record) => ({
        onClick: () => onClickRow(record),
      })}
      className="advertisements-table"
    />
  );
};
