import React, {useState} from "react";
import {Descriptions, Pagination} from "antd";
import AdminLayout from "../_layout/AdminLayout";
import {
  useAdvertisements,
  createAdvertisement,
  updateAdvertisement,
  deleteAdvertisement,
  uploadFileAdvertisement,
} from "api/settings";
import {AdvertisementsTable} from "./component/AdvertisementsTable";
import {AdvertisementItem} from "./component/AdvertisementItem";

const AdvertisementsPage = () => {
  const pageSize = 10;
  const [page, setPage] = useState(1);
  const [, advertisementsData, loading, request] = useAdvertisements(page, pageSize);
  const [currentAD, setCurrentAD] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);

  const onCreateAD = (ad, file) => {
    setBtnLoading(true);
    const additionalData = {
      place: "main",
    };
    createAdvertisement({...ad, ...additionalData}).then(({data}) => {
      if (!file) return request().finally(() => setBtnLoading(false));
      const fileData = new FormData();
      fileData.append("file", file);
      uploadFileAdvertisement(data.advertisement.id, fileData)
        .then(() => {
          setCurrentAD(null);
          request().finally(() => setBtnLoading(false));
        })
        .catch(() => setBtnLoading(false));
    });
  };

  const onUpdateAD = (values) => {
    setBtnLoading(true);
    updateAdvertisement(values.id, values)
      .then(() => request())
      .finally(() => setBtnLoading(false));
  };

  const onRemoveAD = (id) => {
    setBtnLoading(true);
    deleteAdvertisement(id).then(() => {
      setCurrentAD(null);
      request().then(() => setBtnLoading(false));
    });
  };

  return (
    <AdminLayout>
      <div className="content-box">
        <Descriptions
          title={<h1>Advertisements</h1>}
          bordered
          column={1}
          labelStyle={{width: 200}}
          className="mb-4"
        >
          <Descriptions.Item
            label={`${!!currentAD ? "Edit" : "Create"} AD`}
            labelStyle={{fontWeight: "bold"}}
          >
            <AdvertisementItem
              ad={currentAD}
              onCreate={onCreateAD}
              onUpdate={onUpdateAD}
              onRemove={onRemoveAD}
              onReset={() => setCurrentAD(null)}
              btnLoading={btnLoading}
            />
          </Descriptions.Item>
        </Descriptions>
        <AdvertisementsTable
          loading={loading}
          dataSource={advertisementsData?.advertisements}
          onClickRow={setCurrentAD}
          pagination={false}
        />
        <Pagination
          total={advertisementsData?.totalItems}
          current={page}
          pageSize={pageSize}
          showSizeChanger={false}
          onChange={setPage}
          style={{marginTop: 15}}
        />
      </div>
    </AdminLayout>
  );
};

export default AdvertisementsPage;
