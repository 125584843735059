import React, {useState, useCallback} from "react";
import AdminLayout from "../_layout/AdminLayout";
import {Pagination, Form, Input, Button} from "antd";
import {useUsers} from "../../api/users";
import {UsersTable} from "./components/UsersTable";

const UsersPage = () => {
  const pageSize = 20;
  const [page, setPage] = useState(1);
  const [searchForm] = Form.useForm();
  const [{firstName, lastName, mobilePhone, email}, setFilter] = useState({});
  const [, usersData, loading] = useUsers(
    {firstName, lastName, mobilePhone, email},
    page,
    pageSize
  );

  const onReset = useCallback(() => {
    searchForm.resetFields();
    setFilter({firstName: "", lastName: "", mobilePhone: "", email: ""});
  }, [searchForm]);

  const onSearch = useCallback(() => {
    setFilter(searchForm.getFieldsValue());
  }, [setFilter, searchForm]);

  return (
    <AdminLayout>
      <div className="content-box">
        <h1>Users ({usersData?.totalItems ?? "0"} items)</h1>
        <div className="df-row-center mb-3">
          <h3 className="subtitle mr-6">Search -&gt;</h3>
          <Form form={searchForm} layout="inline" onFinish={onSearch}>
            <Form.Item label="First name" name="firstName">
              <Input />
            </Form.Item>
            <Form.Item label="LastName" name="lastName">
              <Input />
            </Form.Item>
            <Form.Item label="Email" name="email">
              <Input />
            </Form.Item>
            <Form.Item label="Phone" name="mobilePhone">
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Search
              </Button>
            </Form.Item>
            <Form.Item>
              <Button htmlType="button" onClick={onReset}>
                Reset
              </Button>
            </Form.Item>
          </Form>
        </div>
        <UsersTable loading={loading} users={usersData?.users} pagination={false} />
        <Pagination
          total={usersData?.totalItems}
          current={page}
          pageSize={20}
          showSizeChanger={false}
          onChange={setPage}
          style={{marginTop: 15}}
        />
      </div>
    </AdminLayout>
  );
};

export default UsersPage;
