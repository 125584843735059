import axiosInstance from "../axios";
import {useCallback, useEffect, useState} from "react";

export const useApi = (
  url,
  {method = "GET", data = {}, params = {}, autoRequest = true} = {}
) => {
  const cacheKey = JSON.stringify({url, method, data, params});
  const [responseData, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const request = useCallback(() => {
    setLoading(true);
    return axiosInstance({
      url,
      method,
      data,
      params,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        setError(err);
        throw err;
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoading, setData, setError, data, params, method, url]);

  useEffect(() => {
    autoRequest && request();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cacheKey]);

  return [error, responseData, loading, request];
};
