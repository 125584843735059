import React, {useCallback, useState} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {Input} from "components/Input";
import {Button} from "components/buttons/Button";
import {showSuccess} from "helpers/notifications.helper";

const AdminRegisterCodePage = inject("store")(
  observer(({store: {admin}}) => {
    const {t} = useTranslation();
    const [code, setCode] = useState(["", "", "", ""]);
    const [loading, setLoading] = useState(false);
    const references = {};
    const current = admin.registerStep;

    const changeInput = (e) => {
      const target = e.target;
      const name = parseInt(target.name);
      const nextIndex = (name + 1).toString();
      const prevIndex = (name - 1).toString();
      const codeStr = code.join("");
      const backspace = "Backspace";

      if (e.key !== backspace && target.value.length) {
        if (name + 1 !== code.length) references[nextIndex].focus();
      } else if (codeStr && e.key === backspace && prevIndex !== "-1") {
        references[prevIndex].focus();
      }
    };

    const inputValue = (e) => {
      const target = e.target;
      const changeCode = [...code];

      if (!changeCode[target.name].length || target.value === "") {
        changeCode[target.name] = target.value;
        setCode(changeCode);
      }
    };

    const prevStep = () => {
      admin.setRegisterStep(current - 1);
    };

    const handleSubmit = () => {
      setLoading(true);
      const codeStr = code.join("");
      admin
        .adminCheckSmsCode(codeStr)
        .then(() => {
          showSuccess("Successfully submitted!");
          admin.setRegisterStep(current + 1);
        })
        .finally(() => {
          setLoading(false);
          setCode(["", "", "", ""]);
        });
    };

    const isValid = useCallback(() => {
      return code.every((x) => x.length === 1);
    }, [code])();

    return (
      <>
        <div className="admin_authentication__code __wrapper modal-auth__form-code">
          <p className="admin_authentication__form __info">{t("AUTH_CODE_MODAL_TEXT")}</p>
          <div className="admin_authentication__code __fields">
            <Input
              value={code[0]}
              onChange={inputValue}
              onKeyUp={changeInput}
              focus={(ref) => (references["0"] = ref)}
              name="0"
              type="number"
              autoFocus
            />
            <Input
              value={code[1]}
              onChange={inputValue}
              onKeyUp={changeInput}
              focus={(ref) => (references["1"] = ref)}
              name="1"
              type="number"
            />
            <Input
              value={code[2]}
              onChange={inputValue}
              onKeyUp={changeInput}
              focus={(ref) => (references["2"] = ref)}
              name="2"
              type="number"
            />
            <Input
              value={code[3]}
              onChange={inputValue}
              onKeyUp={changeInput}
              focus={(ref) => (references["3"] = ref)}
              name="3"
              type="number"
            />
          </div>
          <div className="admin_authentication__form __actions">
            <Button
              type="submit"
              onClick={handleSubmit}
              loading={loading}
              disabled={!isValid}
              name={t("CONFIRM")}
            />
          </div>
          <p className="admin_authentication__form __info">
            <a href="#link" onClick={prevStep}>
              Change phone number
            </a>
          </p>
        </div>
      </>
    );
  })
);

export default AdminRegisterCodePage;
