import React from "react";
import AdminLayout from "../_layout/AdminLayout";
import AdminTranslationsHeader from "./components/AdminTranslationsHeader/AdminTranslationsHeader";
import TranslationsListContent from "./components/TranslationsList/TranslationsListContent";

const AdminTranslationsPage = () => {
  return (
    <AdminLayout>
      <div className="content-box">
        <AdminTranslationsHeader />
      </div>

      <div className="content-box">
        <TranslationsListContent />
      </div>
    </AdminLayout>
  );
};

export default AdminTranslationsPage;
